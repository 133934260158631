import { merge } from '../../utils/merge';
import { notAuthorizedUrl } from './const';
import { storage } from '../storage';
export const createHeaders = (shouldAddAuth) => {
    const token = storage.TOKEN.get();
    const apiKey = storage.API_KEY.get();
    let tokenHeader = {};
    if (shouldAddAuth) {
        tokenHeader = token ? { Authorization: `Bearer ${token}` } : {};
    }
    const apiKeyHeader = { 'X-Merchant-Key': apiKey };
    return merge(tokenHeader, apiKeyHeader);
};
export const handleRequest = (config) => {
    const headers = createHeaders(!notAuthorizedUrl.includes(config.url));
    return merge({ headers }, config);
};
