export function networkInfo() {
  const info =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;

  const type = info?.effectiveType;
  const downlink = info?.downlink;
  const rtt = info?.rtt;
  const saveData = info?.saveData;

  return {
    type,
    downlink,
    rtt,
    saveData,
  };
}

export function languageAndTimeZone() {
  return {
    language: navigator.language || navigator.userLanguage,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
}
