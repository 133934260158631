export class HSLAColor {
    constructor(color) {
        this.getKey = (key) => {
            return this.color[key];
        };
        this.getColor = () => {
            return hslaObjectToString(this.color);
        };
        this.sumColor = (color) => {
            return hslaObjectToString(sumHslaObjects(this.color, color));
        };
        this.color = hslaStringToObject(color);
    }
}
function hslaObjectToString(color) {
    const { h, s, l, a } = color;
    return `hsla(${h},${s}%,${l}%,${a})`;
}
function hslaStringToObject(color) {
    const [h, s, l, a = 1] = color
        .substring(color.indexOf('(') + 1, color.indexOf(')'))
        .split(',')
        .map((item) => parseInt(item));
    return {
        h,
        s,
        l,
        a,
    };
}
function sumHslaObjects(mainColor, increment) {
    const result = { h: 0, l: 0, s: 0, a: 0 };
    Object.keys(mainColor).forEach((key) => {
        const value = mainColor[key] + (increment[key] || 0);
        const isPercentKey = key === 'l' || key === 's';
        switch (key) {
            case 'h':
                result[key] = value;
                break;
            case 's':
            case 'l':
                result[key] = valueInRange(value, 100);
                break;
            case 'a':
                result[key] = valueInRange(value, 1);
        }
    });
    return result;
}
function valueInRange(value, endRange, startRange = 0) {
    return value >= endRange
        ? endRange
        : value <= startRange
            ? startRange
            : value;
}
