import axios from 'axios';
import { handleRequest } from './interceptors';
import { notAuthorizedUrl } from './const';
import { storage } from '../storage';
const httpClientInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/`,
});
httpClientInstance.interceptors.request.use(handleRequest);
const handleError = (err) => {
    const errorObject = JSON.parse(JSON.stringify(err));
    const methodURL = errorObject.config.url;
    const isCurrentMethodNotAuth = Boolean(notAuthorizedUrl.find((item) => item.includes(methodURL)));
    if (errorObject.status === 401 && !isCurrentMethodNotAuth) {
        storage.clearAll();
        window.location.reload();
    }
    throw err;
};
const handleResponse = (res) => {
    return res;
};
httpClientInstance.interceptors.response.use(handleResponse, handleError);
export const httpClient = httpClientInstance;
