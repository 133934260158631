var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getMerchantCurrencies, } from '../../Services/methods/currencies';
import { InitializationError, InitializationErrorsCode, } from '../../Errors/InitializationErrors';
export function getCurrencies(settings) {
    return __awaiter(this, void 0, void 0, function* () {
        const result = yield getMerchantCurrencies();
        const currenciesMap = new Map();
        const filteredPayCurrencies = result.fiat.filter((item) => settings.availablePayCurrency
            ? item.code.toLowerCase() === settings.availablePayCurrency.toLowerCase()
            : true);
        const filteredReceivedCurrencies = filterReceivedCurrencies(result.crypto, settings.availableReceiveCurrency);
        [...filteredPayCurrencies, ...filteredReceivedCurrencies].forEach((item) => {
            currenciesMap.set(item.a3, item);
        });
        const to = groupCurrenciesByCode(filteredReceivedCurrencies);
        if (filteredPayCurrencies.length === 0 ||
            filteredReceivedCurrencies.length === 0) {
            throw new InitializationError({
                code: InitializationErrorsCode.NoOneCurrencyPair,
            });
        }
        return {
            from: filteredPayCurrencies,
            to,
            currenciesMap,
        };
    });
}
export function filterReceivedCurrencies(list, availableReceiveCurrency) {
    if (typeof availableReceiveCurrency === 'string') {
        const currencyCode = availableReceiveCurrency.toLowerCase();
        return list.filter((item) => item.code.toLowerCase() === currencyCode);
    }
    if (availableReceiveCurrency && availableReceiveCurrency.code) {
        const currencyCode = availableReceiveCurrency.code.toLowerCase();
        const currencyProtocol = availableReceiveCurrency.protocol;
        return list.filter((item) => {
            return (item.code.toLowerCase() === currencyCode &&
                (!currencyProtocol || item.protocol === currencyProtocol));
        });
    }
    return list;
}
export function groupCurrenciesByCode(list) {
    const temp = [];
    const codes = new Map();
    for (const currency of list) {
        const isCodeAlreadyInResult = codes.has(currency.code);
        if (isCodeAlreadyInResult) {
            const codeIndexInResult = codes.get(currency.code);
            temp[codeIndexInResult].push(currency);
        }
        else {
            temp.push([currency]);
            codes.set(currency.code, temp.length - 1);
        }
    }
    return temp.map((item) => (item.length !== 1 ? item : item[0]));
}
