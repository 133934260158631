import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from './Modal';
import { SearchInput } from '../CurrencyInput/SearchInput';
import styled from 'styled-components';
import { customScrollBar } from '../cssTemplates/ScrollBar';
const OptionsWrapper = styled.div `
  overflow: auto;
  height: ${(props) => (props.searchEnabled ? 'calc(100% - 106px)' : '100%')};

  ${customScrollBar}
`;
export function ListModal({ label, onClose, isOpen, searchPlaceholder, filterItems, list, renderItems, dataTestIdPrefix, }) {
    const [searchString, setSearchString] = useState('');
    const handleSearchChange = useCallback((e) => {
        setSearchString(e.target.value);
    }, []);
    const handleClose = useCallback(() => {
        setSearchString('');
        onClose();
    }, [onClose]);
    const resultArray = useMemo(() => {
        if (!searchString || !filterItems) {
            return list;
        }
        return list.filter((item) => filterItems(item, searchString));
    }, [list, searchString, filterItems]);
    return (<Modal onClose={handleClose} label={label} isOpen={isOpen} dataTestIdPrefix={dataTestIdPrefix}>
      {Boolean(filterItems) && (<div>
          <SearchInput dataTestId={`${dataTestIdPrefix}-input-search`} value={searchString} onChange={handleSearchChange} autoFocus={true} placeholder={searchPlaceholder}/>
        </div>)}
      <OptionsWrapper searchEnabled={Boolean(filterItems)}>
        {resultArray.map(renderItems)}
      </OptionsWrapper>
    </Modal>);
}
