import React from 'react';
import { useTheme } from 'styled-components';
export const PendingIcon = () => {
    const { colors } = useTheme();
    return (<svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38 75.5C17.2888 75.5 0.5 58.7113 0.5 38C0.5 17.2888 17.2888 0.5 38 0.5C58.7113 0.5 75.5 17.2888 75.5 38C75.5 58.7113 58.7113 75.5 38 75.5ZM41.75 38V19.25H34.25V45.5H56.75V38H41.75Z" fill={colors.processing}/>
    </svg>);
};
export const ErrorIcon = () => {
    const { colors } = useTheme();
    return (<svg width="80" height="70" viewBox="0 0 80 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M43.2472 2.24983L78.9697 64.1248C79.2989 64.6949 79.4721 65.3416 79.4722 65.9998C79.4722 66.6581 79.2989 67.3047 78.9698 67.8748C78.6406 68.4449 78.1673 68.9183 77.5972 69.2474C77.0272 69.5765 76.3805 69.7498 75.7222 69.7498H4.27725C3.61899 69.7498 2.97234 69.5765 2.40228 69.2474C1.83222 68.9183 1.35884 68.4449 1.02972 67.8748C0.700605 67.3047 0.52734 66.6581 0.527344 65.9998C0.527348 65.3416 0.700622 64.6949 1.02975 64.1248L36.7522 2.24983C37.0814 1.67981 37.5548 1.20646 38.1248 0.877356C38.6949 0.548256 39.3415 0.375 39.9997 0.375C40.658 0.375 41.3046 0.548256 41.8747 0.877356C42.4447 1.20646 42.9181 1.67981 43.2472 2.24983ZM36.2497 50.9998V58.4998H43.7497V50.9998H36.2497ZM36.2497 24.7498V43.4998H43.7497V24.7498H36.2497Z" fill={colors.warning}/>
    </svg>);
};
export const PaymentMethodIcons = () => {
    return (<svg width="69" height="12" viewBox="0 0 69 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2701_10289)">
        <g clipPath="url(#clip1_2701_10289)">
          <path d="M16.1019 11.3412H13.1855L15.0097 0.692871H17.9259L16.1019 11.3412Z" fill="#616161"/>
          <path d="M26.6722 0.95334C26.097 0.737887 25.1846 0.5 24.0565 0.5C21.1764 0.5 19.1483 1.9499 19.1359 4.02283C19.1119 5.55223 20.5879 6.4017 21.6918 6.91165C22.8202 7.43274 23.2037 7.77286 23.2037 8.23734C23.1922 8.9507 22.292 9.27952 21.4523 9.27952C20.288 9.27952 19.6641 9.10999 18.716 8.71311L18.3319 8.54298L17.9238 10.9333C18.6078 11.2275 19.868 11.4886 21.1764 11.5C24.2365 11.5 26.2287 10.0725 26.2523 7.8635C26.264 6.65133 25.4846 5.72252 23.8043 4.96354C22.7843 4.47632 22.1596 4.1478 22.1596 3.64929C22.1716 3.1961 22.688 2.73192 23.8394 2.73192C24.7875 2.70918 25.4841 2.92434 26.0118 3.13964L26.2756 3.25271L26.6722 0.95334Z" fill="#616161"/>
          <path d="M30.5473 7.5686C30.7875 6.95687 31.7117 4.5893 31.7117 4.5893C31.6995 4.61203 31.9514 3.96628 32.0954 3.56985L32.2992 4.48737C32.2992 4.48737 32.8515 7.03622 32.9714 7.5686C32.5156 7.5686 31.1233 7.5686 30.5473 7.5686ZM34.1472 0.692627H31.8914C31.1957 0.692627 30.6672 0.885044 30.3671 1.57612L26.0352 11.3408H29.0952C29.0952 11.3408 29.599 10.0265 29.7073 9.74346C30.043 9.74346 33.0199 9.74346 33.4517 9.74346C33.5355 10.1173 33.7997 11.3408 33.7997 11.3408H36.5L34.1472 0.692627Z" fill="#616161"/>
          <path d="M10.7492 0.692871L7.89305 7.95399L7.58095 6.48135C7.05291 4.78211 5.39688 2.93593 3.54883 2.01796L6.16493 11.3299H9.24895L13.833 0.692871H10.7492Z" fill="#616161"/>
          <path d="M5.24021 0.692627H0.548003L0.5 0.907779C4.16021 1.79142 6.5843 3.92141 7.58025 6.48155L6.56022 1.58771C6.39229 0.907628 5.87621 0.715061 5.24021 0.692627Z" fill="#616161"/>
        </g>
      </g>
      <g clipPath="url(#clip2_2701_10289)">
        <path d="M61.377 1.2832H56.1221V10.7176H61.377V1.2832Z" fill="#757575"/>
        <path d="M56.4231 6C56.4223 5.0914 56.6284 4.19452 57.0258 3.37725C57.4232 2.55999 58.0015 1.84377 58.7169 1.2828C57.831 0.587075 56.767 0.154413 55.6465 0.0342676C54.5261 -0.0858778 53.3944 0.111337 52.3808 0.603379C51.3673 1.09542 50.5128 1.86244 49.9149 2.81675C49.317 3.77106 49 4.87417 49 6C49 7.12582 49.317 8.22893 49.9149 9.18324C50.5128 10.1376 51.3673 10.9046 52.3808 11.3966C53.3944 11.8887 54.5261 12.0859 55.6465 11.9657C56.767 11.8456 57.831 11.4129 58.7169 10.7172C58.0015 10.1562 57.4232 9.44 57.0258 8.62274C56.6284 7.80547 56.4223 6.90859 56.4231 6Z" fill="#616161"/>
        <path d="M68.4998 6C68.4998 7.12581 68.1828 8.22891 67.585 9.18322C66.9872 10.1375 66.1327 10.9046 65.1191 11.3966C64.1056 11.8887 62.974 12.0859 61.8535 11.9657C60.7331 11.8456 59.6691 11.4129 58.7832 10.7172C59.498 10.1557 60.0758 9.43932 60.4732 8.62219C60.8705 7.80506 61.077 6.90846 61.077 6C61.077 5.09154 60.8705 4.19494 60.4732 3.37781C60.0758 2.56068 59.498 1.84434 58.7832 1.28281C59.6691 0.587077 60.7331 0.15441 61.8535 0.0342662C62.974 -0.085878 64.1056 0.111346 65.1191 0.603393C66.1327 1.09544 66.9872 1.86246 67.585 2.81678C68.1828 3.77109 68.4998 4.87419 68.4998 6Z" fill="#9E9E9E"/>
      </g>
      <defs>
        <clipPath id="clip0_2701_10289">
          <rect width="37" height="12" fill="white"/>
        </clipPath>
        <clipPath id="clip1_2701_10289">
          <rect width="36" height="11" fill="white" transform="translate(0.5 0.5)"/>
        </clipPath>
        <clipPath id="clip2_2701_10289">
          <rect width="19.5003" height="12" fill="white" transform="translate(49)"/>
        </clipPath>
      </defs>
    </svg>);
};
export const VisaIcon = () => {
    return (<svg width="38" height="12" viewBox="0 0 38 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2360_30154)">
        <g clipPath="url(#clip1_2360_30154)">
          <path d="M16.8519 11.3416H13.9355L15.7597 0.693359H18.6759L16.8519 11.3416Z" fill="#616161"/>
          <path d="M27.4222 0.95334C26.847 0.737887 25.9346 0.5 24.8065 0.5C21.9264 0.5 19.8983 1.9499 19.8859 4.02283C19.8619 5.55223 21.3379 6.4017 22.4418 6.91165C23.5702 7.43274 23.9537 7.77286 23.9537 8.23734C23.9422 8.9507 23.042 9.27952 22.2023 9.27952C21.038 9.27952 20.4141 9.10999 19.466 8.71311L19.0819 8.54298L18.6738 10.9333C19.3578 11.2275 20.618 11.4886 21.9264 11.5C24.9865 11.5 26.9787 10.0725 27.0023 7.8635C27.014 6.65133 26.2346 5.72252 24.5543 4.96354C23.5343 4.47632 22.9096 4.1478 22.9096 3.64929C22.9216 3.1961 23.438 2.73192 24.5894 2.73192C25.5375 2.70918 26.2341 2.92434 26.7618 3.13964L27.0256 3.25271L27.4222 0.95334Z" fill="#616161"/>
          <path d="M31.2973 7.56934C31.5375 6.95761 32.4617 4.59003 32.4617 4.59003C32.4495 4.61277 32.7014 3.96701 32.8454 3.57058L33.0492 4.4881C33.0492 4.4881 33.6015 7.03695 33.7214 7.56934C33.2656 7.56934 31.8733 7.56934 31.2973 7.56934ZM34.8972 0.693359H32.6414C31.9457 0.693359 31.4172 0.885777 31.1171 1.57685L26.7852 11.3415H29.8452C29.8452 11.3415 30.349 10.0272 30.4573 9.74419C30.793 9.74419 33.7699 9.74419 34.2017 9.74419C34.2855 10.118 34.5497 11.3415 34.5497 11.3415H37.25L34.8972 0.693359Z" fill="#616161"/>
          <path d="M11.4992 0.693359L8.64305 7.95447L8.33095 6.48183C7.80291 4.7826 6.14688 2.93642 4.29883 2.01845L6.91493 11.3304H9.99895L14.583 0.693359H11.4992Z" fill="#616161"/>
          <path d="M5.99021 0.693359H1.298L1.25 0.908511C4.91021 1.79215 7.3343 3.92214 8.33025 6.48229L7.31022 1.58845C7.14229 0.908361 6.62621 0.715793 5.99021 0.693359Z" fill="#616161"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2360_30154">
          <rect width="37" height="12" fill="white" transform="translate(0.75)"/>
        </clipPath>
        <clipPath id="clip1_2360_30154">
          <rect width="36" height="11" fill="white" transform="translate(1.25 0.5)"/>
        </clipPath>
      </defs>
    </svg>);
};
export const MasterCardIcon = () => {
    return (<svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2360_30161)">
        <path d="M13.127 1.2832H7.87207V10.7176H13.127V1.2832Z" fill="#757575"/>
        <path d="M8.17311 6C8.17228 5.0914 8.37837 4.19452 8.77577 3.37725C9.17318 2.55999 9.75148 1.84377 10.4669 1.2828C9.58096 0.587075 8.51695 0.154413 7.39651 0.0342676C6.27607 -0.0858778 5.1444 0.111337 4.13085 0.603379C3.1173 1.09542 2.26276 1.86244 1.6649 2.81675C1.06705 3.77106 0.75 4.87417 0.75 6C0.75 7.12582 1.06705 8.22893 1.6649 9.18324C2.26276 10.1376 3.1173 10.9046 4.13085 11.3966C5.1444 11.8887 6.27607 12.0859 7.39651 11.9657C8.51695 11.8456 9.58096 11.4129 10.4669 10.7172C9.75149 10.1562 9.17319 9.44 8.77578 8.62274C8.37838 7.80547 8.17229 6.90859 8.17311 6Z" fill="#616161"/>
        <path d="M20.2498 6C20.2498 7.12581 19.9328 8.22891 19.335 9.18322C18.7372 10.1375 17.8827 10.9046 16.8691 11.3966C15.8556 11.8887 14.724 12.0859 13.6035 11.9657C12.4831 11.8456 11.4191 11.4129 10.5332 10.7172C11.248 10.1557 11.8258 9.43932 12.2232 8.62219C12.6205 7.80506 12.827 6.90846 12.827 6C12.827 5.09154 12.6205 4.19494 12.2232 3.37781C11.8258 2.56068 11.248 1.84434 10.5332 1.28281C11.4191 0.587077 12.4831 0.15441 13.6035 0.0342662C14.724 -0.085878 15.8556 0.111346 16.8691 0.603393C17.8827 1.09544 18.7372 1.86246 19.335 2.81678C19.9328 3.77109 20.2498 4.87419 20.2498 6Z" fill="#9E9E9E"/>
      </g>
      <defs>
        <clipPath id="clip0_2360_30161">
          <rect width="19.5003" height="12" fill="white" transform="translate(0.75)"/>
        </clipPath>
      </defs>
    </svg>);
};
