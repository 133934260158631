import React, { createContext, useContext, useLayoutEffect, useState, } from 'react';
import { useMerchant } from './MerchantContext';
export var FeatureFlag;
(function (FeatureFlag) {
    FeatureFlag["DIRECT_PAYMENT"] = "WIDGET_DIRECT_PAYMENT";
    FeatureFlag["CUSTOM_KYC"] = "WIDGET_CUSTOM_KYC";
    FeatureFlag["WALLET_CREATION"] = "WIDGET_CREATE_WALLET";
    FeatureFlag["ENABLE_WHITELABEL"] = "WIDGET_ENABLE_WHITELABEL";
    FeatureFlag["ENABLE_CRYPTOLESS_MODE"] = "WIDGET_ENABLE_CRYPTOLESS_MODE";
    FeatureFlag["ENABLE_APPLE_PAY"] = "ENABLE_APPLE_PAY";
})(FeatureFlag || (FeatureFlag = {}));
function getFeatureFlagsRecord(flags = []) {
    return Object.values(FeatureFlag).reduce((acc, flag) => {
        acc[flag] = flags.includes(flag) || false;
        return acc;
    }, {});
}
const initialFlags = getFeatureFlagsRecord();
const FeatureFlagContext = createContext(initialFlags);
export const FeatureFlagProvider = ({ children }) => {
    const [flags, setFlags] = useState(initialFlags);
    const merchant = useMerchant();
    useLayoutEffect(() => {
        const updatedFlags = getFeatureFlagsRecord(merchant.features);
        setFlags(updatedFlags);
    }, [merchant.features]);
    return (<FeatureFlagContext.Provider value={flags}>
      {children}
    </FeatureFlagContext.Provider>);
};
export function useFeatureFlag(flag) {
    const flags = useContext(FeatureFlagContext);
    return flags[flag];
}
