import React, { useCallback, useMemo } from 'react';
import { CurrencyOption } from './CurrencyOption';
import { ListModal } from '../Modals/ListModal';
export const CurrencyModal = ({ isOpen, onClose, label, onChange, currencies, searchEnabled, searchPlaceholder, currency, hideProtocol, dataTestIdPrefix, }) => {
    const filterCurrencies = useMemo(() => {
        return searchEnabled
            ? (currency, searchString) => {
                const code = Array.isArray(currency)
                    ? currency[0].code.toLowerCase()
                    : currency.code.toLowerCase();
                const names = Array.isArray(currency)
                    ? currency.map((item) => item.name.toLowerCase())
                    : [currency.name.toLowerCase()];
                const values = [...names, code];
                return values.some((value) => value.includes(searchString.toLowerCase()));
            }
            : undefined;
    }, [searchEnabled]);
    const renderCurrencyItemElement = useCallback((item, index) => {
        return (<CurrencyOption hideProtocol={hideProtocol} key={index} onCurrencySelect={onChange} currency={item} selectedCurrency={currency} dataTestIdPrefix={dataTestIdPrefix}/>);
    }, [hideProtocol, onChange, currency, dataTestIdPrefix]);
    return (<ListModal searchPlaceholder={searchPlaceholder} onClose={onClose} label={label} isOpen={isOpen} list={currencies} filterItems={filterCurrencies} renderItems={renderCurrencyItemElement} dataTestIdPrefix={dataTestIdPrefix}/>);
};
