var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { i18n } from '@lingui/core';
import { detect, fromNavigator, fromStorage } from '@lingui/detect-locale';
import { locales } from '../../lingui.config';
export const defaultLocale = 'en';
export function loadAndInitLocaleAsync(localeCode) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const detectedLocale = (_a = localeCode !== null && localeCode !== void 0 ? localeCode : detect(fromStorage('lang'), fromNavigator())) !== null && _a !== void 0 ? _a : defaultLocale;
        const locale = detectedLocale.split('-')[0];
        if (!locales.includes(locale)) {
            return loadAndInitLocaleAsync(defaultLocale);
        }
        const { messages } = yield import(`@lingui/loader!./locales/${locale}.messages.po`);
        i18n.load(locale, messages);
        i18n.activate(locale);
        return i18n;
    });
}
