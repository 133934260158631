const PREFIX = 'Invariant failed';
const isProd = process.env.NODE_ENV === 'production';
export function invariant(condition, message) {
    if (condition) {
        return;
    }
    if (isProd) {
        throw new Error(PREFIX);
    }
    else {
        throw new Error(`${PREFIX}: ${message || 'no message'}`);
    }
}
