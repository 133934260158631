import React from 'react';
import { useTheme } from 'styled-components';
export const CaptionLogo = () => {
    const theme = useTheme();
    return (<svg width="78" height="20" viewBox="0 0 84 14" fill={theme.colors.textBodySw} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2516_9769)">
        <path d="M27.0605 7.93768C27.0605 9.22887 28.1943 10.1238 29.9974 10.1238C31.7732 10.1238 33.0163 9.29279 33.0163 7.92489C33.0163 6.90217 32.4016 6.41637 31.2815 6.18626L30.0111 5.93057C29.533 5.8283 29.2735 5.66211 29.2735 5.36807C29.2735 5.0229 29.574 4.79279 30.0521 4.79279C30.5575 4.79279 30.8444 5.08682 30.8444 5.52148H32.8387C32.8387 4.23029 31.7323 3.36097 30.1477 3.36097C28.5085 3.36097 27.2928 4.12802 27.2928 5.45756C27.2928 6.58256 27.9075 7.09393 29.1642 7.34961L30.4209 7.60529C30.9263 7.70756 31.0356 7.91211 31.0356 8.10387C31.0356 8.43626 30.7351 8.65359 30.0794 8.65359C29.4374 8.65359 29.0822 8.38512 29.0549 7.93768H27.0605Z"/>
        <path d="M35.459 9.95756H37.508L38.1637 7.96325C38.6008 6.62092 38.7237 6.12234 38.7784 5.85387C38.8194 6.12234 38.9969 6.8766 39.3384 7.92489L39.9941 9.95756H41.9475L44.338 3.56552H42.1114L41.4557 5.64932C41.3874 5.87944 41.0596 7.00444 40.9776 7.46467C40.9093 7.05558 40.5815 5.86665 40.5132 5.64932L39.8712 3.56552H37.7129L37.0162 5.64932C36.7157 6.54421 36.6474 6.78711 36.5245 7.46467C36.4152 6.82546 36.2649 6.19904 36.101 5.64932L35.4727 3.56552H33.2597L35.459 9.95756Z"/>
        <path d="M46.3058 2.56836C46.9615 2.56836 47.4942 2.06978 47.4942 1.44336C47.4942 0.816939 46.9615 0.331143 46.3058 0.331143C45.6364 0.331143 45.1037 0.816939 45.1037 1.44336C45.1037 2.06978 45.6364 2.56836 46.3058 2.56836ZM45.2676 9.95756H47.3713V3.56552H45.2676V9.95756Z"/>
        <path d="M48.9494 13.0257H51.0531V9.31836C51.4492 9.80415 52.3234 10.1365 53.266 10.1365C55.3013 10.1365 56.4761 8.67915 56.4761 6.65927C56.4761 4.60103 55.1511 3.36097 53.2523 3.36097C52.2825 3.36097 51.4219 3.75728 51.0394 4.34535L50.9301 3.56552H48.9494V13.0257ZM51.0667 6.76154C51.0667 5.80273 51.7497 5.16353 52.7332 5.16353C53.7304 5.16353 54.3588 5.81552 54.3588 6.76154C54.3588 7.70756 53.7304 8.35955 52.7332 8.35955C51.7497 8.35955 51.0667 7.72035 51.0667 6.76154Z"/>
        <path d="M60.8561 10.1238C62.7276 10.1238 64.0799 9.21609 64.3258 7.79705H62.3997C62.2495 8.25728 61.6894 8.52575 60.8971 8.52575C59.9682 8.52575 59.4628 8.12944 59.3399 7.28569L64.2985 7.26012V6.76154C64.2985 4.67773 62.9598 3.36097 60.8152 3.36097C58.7525 3.36097 57.3182 4.75444 57.3182 6.74876C57.3182 8.71751 58.7935 10.1238 60.8561 10.1238ZM60.8288 4.95898C61.6757 4.95898 62.2085 5.39364 62.2085 6.0712H59.3672C59.5311 5.30415 59.9956 4.95898 60.8288 4.95898Z"/>
        <path d="M67.6478 9.95756V0.318359H65.5442V9.95756H67.6478Z"/>
        <path d="M73.7611 3.56552V6.88938C73.7611 7.93768 73.3923 8.37234 72.4907 8.37234C71.6302 8.37234 71.2477 7.91211 71.2477 6.86381V3.56552H69.144V7.57972C69.144 9.15217 70.1002 10.1238 71.6575 10.1238C72.4771 10.1238 73.324 9.7658 73.7475 9.24166L73.8841 9.95756H75.8648V3.56552H73.7611Z"/>
        <path d="M79.1782 9.95756L80.3256 8.11665L81.5004 9.95756H83.9182L81.7462 6.82546L84.0001 3.56552H81.6506L80.4759 5.5087L79.2191 3.56552H76.7877L79.0825 6.86381L76.7877 9.95756H79.1782Z"/>
        <g clipPath="url(#clip1_2516_9769)">
          <path d="M2.98754 8.43878C2.58749 8.03873 2.58749 7.39012 2.98754 6.99007L8.2391 1.73851C8.63915 1.33846 9.28776 1.33846 9.68781 1.73851L14.9394 6.99007C15.3394 7.39012 15.3394 8.03873 14.9394 8.43878L9.6878 13.6903C9.28775 14.0904 8.63915 14.0904 8.2391 13.6903L2.98754 8.43878Z"/>
          <path d="M11.5738 12.1666C11.3738 12.3666 11.3738 12.6909 11.5738 12.8909L12.3732 13.6903C12.7733 14.0904 13.4219 14.0904 13.8219 13.6903L19.0735 8.43878C19.4736 8.03873 19.4736 7.39012 19.0735 6.99007L13.8219 1.73851C13.4219 1.33846 12.7733 1.33846 12.3732 1.73851L11.5739 2.53791C11.3738 2.73793 11.3738 3.06223 11.5738 3.26226L15.3017 6.99007C15.7017 7.39012 15.7017 8.03873 15.3017 8.43878L11.5738 12.1666Z"/>
          <path d="M15.7076 12.1667C15.5076 12.3667 15.5076 12.691 15.7076 12.891L16.507 13.6903C16.907 14.0904 17.5556 14.0904 17.9557 13.6903L23.2072 8.43878C23.6073 8.03873 23.6073 7.39012 23.2072 6.99007L17.9557 1.73851C17.5556 1.33846 16.907 1.33846 16.507 1.73851L15.7076 2.53784C15.5076 2.73787 15.5076 3.06217 15.7076 3.2622L19.4355 6.99007C19.8356 7.39012 19.8356 8.03873 19.4355 8.43878L15.7076 12.1667Z"/>
        </g>
      </g>
    </svg>);
};
