import React from 'react';
import { ApplicationProvider } from './ApplicationContext';
import { SettingContextProvider } from './SettingsContext';
import { AnalyticsContextProvider } from './AnalyticsContext';
import { FeatureFlagProvider } from './FeatureFlagContext';
import { CurrenciesContextProvider } from './CurrenciesContext';
import { MerchantContextProvider } from './MerchantContext';
import { LocationDataProvider } from './LocationDataContext';
import { CountriesWithPhoneProvider } from './CountriesWithPhoneContext';
export const ContextWrapper = ({ application, settings, merchant, currencies, children, location, }) => {
    return (<ApplicationProvider application={application}>
      <SettingContextProvider settings={settings}>
        <MerchantContextProvider merchant={merchant}>
          <CurrenciesContextProvider currencies={currencies}>
            <LocationDataProvider locationData={location}>
              <CountriesWithPhoneProvider>
                <FeatureFlagProvider>
                  <AnalyticsContextProvider>
                    {children}
                  </AnalyticsContextProvider>
                </FeatureFlagProvider>
              </CountriesWithPhoneProvider>
            </LocationDataProvider>
          </CurrenciesContextProvider>
        </MerchantContextProvider>
      </SettingContextProvider>
    </ApplicationProvider>);
};
