var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCurrentMerchant } from '../../Services/methods/merchant';
import { InitializationError, InitializationErrorsCode, } from '../../Errors/InitializationErrors';
export function getMerchant() {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const res = yield getCurrentMerchant();
            return res.data;
        }
        catch (e) {
            if ((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.status) {
                throw new InitializationError({
                    code: InitializationErrorsCode.WrongApiKey,
                });
            }
            throw new InitializationError({
                code: InitializationErrorsCode.UnknowingError,
            });
        }
    });
}
