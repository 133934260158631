import { css } from 'styled-components';
export const customScrollBar = css `
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.main};
    border-radius: ${(props) => props.theme.borderRadiusBase};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.textBodyHover};
  }

  ::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 12px;
  }
`;
