import React, { useEffect } from 'react';
import { Modal } from '../Modals/Modal';
import { MenuItem } from './MenuItem';
import { MenuConfig } from './Config';
import { msg } from '@lingui/macro';
import { LanguageIcon } from './Icons';
import { useLingui } from '@lingui/react';
export const Menu = (props) => {
    const { _ } = useLingui();
    useEffect(() => {
        if (props.isOpen) {
            window.Intercom('boot', {
                app_id: 'no0vwrwo',
                // todo: We can pass user information
                //Website visitor so may not have any user related info
                // email: 'example@example.com',
                // user_id: 'abc123',
                // created_at: 1234567890,
            });
        }
        else {
            window.Intercom('shutdown');
        }
    }, [props.isOpen]);
    return (<Modal label={_(msg `Menu`)} dataTestIdPrefix="menu" anchorOrigin="top" {...props}>
      {props.showLanguageSelectorMenuItem && (<MenuItem label={msg `Language`} onClick={props.onLanguageSelectorClick} Icon={<LanguageIcon />}/>)}

      {MenuConfig.map((item) => (<MenuItem key={item.label.id} {...item}/>))}
    </Modal>);
};
