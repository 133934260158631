const userToken = 'user_token';
export function setUserToken(s) {
    localStorage.setItem(userToken, s);
}
export function removeUserToken() {
    localStorage.removeItem(userToken);
}
export function getUserToken() {
    return localStorage.getItem(userToken);
}
