import styles from './PageLayout.module.css';
import React, { useEffect, useState, useCallback, } from 'react';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Menu } from '../Menu';
import { Loading } from '../Loading';
import { ProgressLine } from './ProgressLine';
import { LanguageSelectorModal } from '../LanguageSelector';
export const PageLayout = ({ children, headerChildren, footer, headerLabel, hideHeaderChip, loading, customBackClick, hideBackArrow, disableBodyPadding, className, hideProgressLine, showLanguageSelectorInHeader, }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Enter' &&
                (footer === null || footer === void 0 ? void 0 : footer.buttonClick) &&
                !(footer === null || footer === void 0 ? void 0 : footer.actionButtonLoader) &&
                !footer.actionButtonDisable) {
                footer.buttonClick();
            }
        };
        document.addEventListener('keydown', handleKeyDown, true);
        return () => {
            document.removeEventListener('keydown', handleKeyDown, true);
        };
    }, [footer]);
    const handleOnBurgerClick = useCallback(() => {
        setMenuOpen((isOpen) => !isOpen);
    }, []);
    const handleOnLanguageSelectorClick = useCallback(() => {
        setLanguageSelectorOpen(true);
        setMenuOpen(false);
    }, []);
    const handleLanguageModalClose = useCallback(() => {
        setLanguageSelectorOpen(false);
    }, []);
    const wrapperClassName = `${styles.wrapper} ${(footer === null || footer === void 0 ? void 0 : footer.actionButtonLoader) || (footer === null || footer === void 0 ? void 0 : footer.secondaryButtonLoader)
        ? styles.wrapper_type_blocked
        : ''}`;
    return (<>
      <div className={wrapperClassName}>
        {!hideProgressLine && <ProgressLine />}
        <Header hideBackArrow={hideBackArrow} customBackClick={customBackClick} showLanguageSelectorButton={showLanguageSelectorInHeader} onLanguageSelectorClick={handleOnLanguageSelectorClick} onBurgerClick={handleOnBurgerClick} hideChip={hideHeaderChip} label={headerLabel} headerChildren={headerChildren}/>
        <div className={`${styles.body_wrapper} ${disableBodyPadding ? styles.body_wrapper_without_padding : ''} ${className ? className : ''}`}>
          {loading ? <Loading /> : children}
        </div>
        <Footer {...footer}/>
      </div>
      <Menu isOpen={menuOpen} showLanguageSelectorMenuItem={!showLanguageSelectorInHeader} onLanguageSelectorClick={handleOnLanguageSelectorClick} onClose={handleOnBurgerClick}/>
      <LanguageSelectorModal isOpen={languageSelectorOpen} onClose={handleLanguageModalClose}/>
    </>);
};
export const AnimationPageLayout = ({ children }) => {
    return (<div className={styles.wrapper}>
      <div className={`${styles.body_wrapper} ${styles.body_wrapper_position_relative}`}>
        {children}
      </div>
    </div>);
};
