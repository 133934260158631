import { AMLSvg, CookieSvg, PerksSvg, PrivacySvg, SupportIcon, TermsSvg, } from './Icons';
import React from 'react';
import { msg } from '@lingui/macro';
export const MenuConfig = [
    {
        label: msg `Support`,
        onClick: () => {
            window.Intercom('showNewMessage');
        },
        Icon: <SupportIcon />,
    },
    {
        label: msg `Perks`,
        Icon: <PerksSvg />,
        href: 'https://portal.zeroid.org/',
    },
    {
        label: msg `Terms of Service`,
        href: 'https://swipelux.com/terms_of_use',
        Icon: <TermsSvg />,
    },
    {
        label: msg `Privacy Policy`,
        href: 'https://swipelux.com/privacy_policy',
        Icon: <PrivacySvg />,
    },
    {
        label: msg `AML Policy`,
        href: 'https://swipelux.com/aml_policy',
        Icon: <AMLSvg />,
    },
    {
        label: msg `Cookies policy`,
        href: 'https://swipelux.com/cookie_policy',
        Icon: <CookieSvg />,
    },
];
