import React, { useEffect, useMemo } from 'react';
import { transparentize, getContrast } from 'polished';
import styled, { ThemeProvider } from 'styled-components';
import { HSLAColor } from './HSLA';
import { checkColorAndConvertToHsla } from './colorConverter/checkColorAndConvertToHsla';
const AppWrapper = styled.div `
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  position: relative;
  background: ${(props) => props.theme.colors.background};
  width: 100%;
  min-width: 320px;
  max-width: 400px;
  border-radius: 24px;
`;
const defaultColors = {
    main: '#000000',
    background: '#FFFFFF',
    processing: '#FFA400',
    warning: '#ED0A34',
    success: '#58CB4E',
    link: '#F24F21',
};
const baseColors = {
    white: '#FFF',
    black: '#000',
    gray50: '#FAFAFA',
    gray100: '#F5F5F5',
    gray200: '#EEEEEE',
    gray300: '#E0E0E0',
    gray400: '#BDBDBD',
    gray500: '#BDBDBD',
    gray600: '#757575',
    gray700: '#616161',
    gray800: '#424242',
    gray900: '#212121',
};
const brightThemeColors = {
    textBodyMain: baseColors.black,
    textBodyAdd: baseColors.gray700,
    textBodyPlaceholder: transparentize(0.8, baseColors.black),
    bordersDefault: transparentize(0.9, baseColors.black),
    bordersHover: baseColors.black,
    dieDefault: transparentize(0.96, baseColors.black),
    dieHover: transparentize(0.92, baseColors.black),
    textBodySw: transparentize(0.4, baseColors.black),
};
const darkThemeColors = {
    textBodyMain: baseColors.white,
    textBodyAdd: baseColors.gray400,
    textBodyPlaceholder: transparentize(0.8, baseColors.white),
    bordersDefault: transparentize(0.8, baseColors.white),
    bordersHover: baseColors.white,
    dieDefault: transparentize(0.9, baseColors.white),
    dieHover: transparentize(0.8, baseColors.black),
    textBodySw: transparentize(0.4, baseColors.white),
};
function getMainColors(customColors) {
    const mainColors = customColors
        ? Object.assign(Object.assign({}, defaultColors), customColors) : defaultColors;
    Object.keys(mainColors).forEach((key) => {
        try {
            transparentize(0.8, mainColors[key]);
        }
        catch (e) {
            mainColors[key] = defaultColors[key];
            // eslint-disable-next-line no-console
            console.error(`Format of colors.${key} is not valid`);
        }
    });
    return mainColors;
}
function createColors(customColors) {
    const mainColors = getMainColors(customColors);
    const mainColor = new HSLAColor(checkColorAndConvertToHsla(mainColors.main));
    let btnBodyHover;
    let btnBodyPressed;
    if (mainColor.getKey('l') >= 95 || mainColor.getKey('l') <= 10) {
        btnBodyHover =
            mainColor.getKey('l') >= 95
                ? mainColor.sumColor({ l: -5 })
                : mainColor.sumColor({ l: 10 });
        btnBodyPressed = mainColor.getColor();
    }
    else {
        btnBodyHover = mainColor.sumColor({ l: 5 });
        btnBodyPressed = mainColor.sumColor({ l: -5 });
    }
    const colorsFromMain = {
        btnBodyHover,
        btnBodyPressed,
        mainFocus: mainColor.sumColor({ a: -0.7 }),
        btnSecondDefault: mainColor.sumColor({ a: -0.9 }),
        btnSecondHover: mainColor.sumColor({ a: -0.8 }),
        btnSecondPressed: mainColor.sumColor({ a: -0.7 }),
        textBtn: 
        // @todo
        getContrast(mainColors.main, baseColors.white) + 0.5 >
            getContrast(mainColors.main, baseColors.black)
            ? baseColors.white
            : baseColors.black,
    };
    const variant = getContrast(mainColors.background, baseColors.white) >
        getContrast(mainColors.background, baseColors.black)
        ? 'dark'
        : 'bright';
    const colorsFromBg = variant === 'dark' ? darkThemeColors : brightThemeColors;
    return Object.assign(Object.assign(Object.assign(Object.assign({}, colorsFromMain), colorsFromBg), mainColors), { type: variant });
}
function createTheme(customColors) {
    return {
        base: '4px',
        colors: createColors(customColors),
    };
}
export const ThemeWrapper = ({ children, customColors, }) => {
    const theme = useMemo(() => createTheme(customColors), [customColors]);
    useEffect(() => {
        Object.keys(theme.colors).forEach((key) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            document.documentElement.style.setProperty(`--${key}`, theme.colors[key]);
        });
    }, [theme]);
    return (<ThemeProvider theme={theme}>
      <AppWrapper>
        <>
          {children}
          <div id="modal-container-element"/>
        </>
      </AppWrapper>
    </ThemeProvider>);
};
