var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, makeObservable, observable, runInAction } from 'mobx';
import { createOrder, getCurrentOrder } from '../methods/order';
import { storage } from '../storage';
const MIN_UPDATE_TIME = 5000;
export class OrderController {
    constructor(order, externalId) {
        this.removeOrder = () => {
            storage.ORDER_ID.remove();
            storage.TOKEN.remove();
            storage.USER_UID.remove();
            this.order = undefined;
        };
        this.order = order;
        this.error = false;
        this.ttl = MIN_UPDATE_TIME;
        this.externalId = externalId;
        makeObservable(this, {
            order: observable,
            getOrder: action,
        });
    }
    getOrder() {
        return __awaiter(this, void 0, void 0, function* () {
            clearTimeout(this.timeout);
            try {
                const res = yield getCurrentOrder();
                // NOTE: for some reason can't update `this.order` directly inside this action,
                // but wrapping with `runInAction` works fine
                runInAction(() => {
                    this.order = res.data;
                    this.error = false;
                    this.ttl = Math.max(res.data.rateTtl * 1000, MIN_UPDATE_TIME);
                });
                return this.order;
            }
            catch (e) {
                this.error = true;
            }
            finally {
                this.timeout = setTimeout(() => {
                    this.getOrder();
                }, this.ttl);
            }
        });
    }
    startWatching() {
        return this.getOrder();
    }
    stopWatching() {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    }
    createOrder(userData, dto, captcha, extendedCaptcha) {
        return __awaiter(this, void 0, void 0, function* () {
            const externalId = typeof this.externalId === 'function'
                ? yield this.externalId()
                : this.externalId;
            return (yield createOrder(Object.assign({ captcha, user: {
                    phone: userData.phone,
                    email: userData.email,
                    token: userData.userToken,
                }, targetAddress: userData.targetAddress, externalId,
                extendedCaptcha }, dto))).data;
        });
    }
}
