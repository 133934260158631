import React, { useContext } from 'react';
import { invariant } from '../../utils/invariant';
export const ApplicationContext = React.createContext(undefined);
export const ApplicationProvider = ({ children, application, }) => {
    return (<ApplicationContext.Provider value={application}>
      {children}
    </ApplicationContext.Provider>);
};
export function useApplication() {
    const application = useContext(ApplicationContext);
    invariant(application, 'Application is undefined');
    return application;
}
export function useOrder() {
    const application = useApplication();
    const order = application.orderFacade.order;
    invariant(order, 'Order is null');
    return order;
}
export function useStepController() {
    const application = useApplication();
    return application.stepController;
}
