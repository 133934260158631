import React from 'react';
import styled, { useTheme } from 'styled-components';
const StyledLabel = styled.label `
  position: relative;
  margin: 0 32px;
`;
const StyledSVG = styled.svg `
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: silver;

  display: inline-block;
  width: 24px;
  height: 24px;
  stroke-width: 0;
`;
const SearchInputElement = styled.input `
  width: calc(100% - 104px - 24px); // margin + padding + border
  border: 1px solid ${(props) => `${props.theme.colors.bordersDefault}`};
  background: ${(props) => ` ${props.theme.colors.dieDefault}`};
  padding: 19px 19px 19px calc(19px + 24px);
  font-size: 18px;
  line-height: 24px;
  border-radius: 12px;
  color: ${(props) => `${props.theme.colors.textBodyMain}`};
  margin-bottom: 24px;

  &::placeholder {
    color: ${(props) => `${props.theme.colors.textBodyAdd}`};
  }

  &:focus-visible {
    outline: none;
    border-color: ${(props) => ` ${props.theme.colors.textBodyMain}`};
  }
`;
function SearchIcon() {
    const theme = useTheme();
    return (<StyledSVG width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" fill={theme.colors.textBodyMain}/>
    </StyledSVG>);
}
export const SearchInput = ({ onChange, placeholder, autoFocus, value, dataTestId, }) => {
    return (<StyledLabel>
      <SearchIcon />
      <SearchInputElement data-test-id={dataTestId} placeholder={placeholder} autoFocus={autoFocus} onChange={onChange} value={value}/>
    </StyledLabel>);
};
