import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CurrencyModal } from './CurrencyModal';
import { CurrencyIcon } from '../BaseComponents/CurrencyIcon';
import { ArrowIcon } from '../Icons/ArrowIcon';
import { NumberInput } from './NumberInput';
import styles from './CurrencyInput.module.css';
export const InputWrapper = styled.div `
  width: 100%;
  color: ${(props) => props.error ? props.theme.colors.warning : props.theme.colors.textBodyMain};
  border-bottom: 1px solid
    ${(props) => ` ${props.error
    ? props.theme.colors.warning
    : props.theme.colors.bordersDefault}`};
  padding: 12px 0;
  display: flex;
`;
const InputSkeleton = styled.div `
  width: 100%;
  background-image: linear-gradient(
    90deg,
    #9d9c9c 0px,
    #b9b9b9 40px,
    #9d9c9c 80px
  );
  animation: shine-loading-image 2s infinite ease-out;
  margin: 12px;
  border-radius: 4px;

  @keyframes shine-loading-image {
    0% {
      background-position: -32px;
    }
    40%,
    100% {
      background-position: 208px;
    }
  }
`;
const ErrorLine = styled.div `
  color: ${(props) => props.theme.colors.warning};
  font-size: 14px;
  line-height: 20px;
  min-height: 24px;
`;
export const StyledButton = styled.button `
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disable ? 'auto' : 'pointer')};
  background: ${(props) => props.theme.colors.dieDefault};
  color: ${(props) => props.theme.colors.textBodyMain};
  font-size: 20px;
  line-height: 24px;
  border-radius: 24px;
  padding: 12px;
  border: none;
  pointer-events: ${(props) => (props.disable ? 'none' : 'auto')};

  span {
    margin: 0 8px;
  }

  &:hover {
    background: ${(props) => props.disable
    ? props.theme.colors.dieDefault
    : props.theme.colors.dieHover};
  }
`;
const CurrencyCodeSpan = styled.span `
  max-width: 70px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.colors.textBodyMain};
`;
export const CurrencyInput = ({ label, amount, currency, currencies, currencyModalLabel, onChange, dataTestIdPrefix, searchPlaceholder, searchEnabled, error, skeleton, amountInputName, currencyInputName, hideProtocol, precision, }) => {
    const [currencySelectOpen, setCurrencySelectOpen] = useState(false);
    const [sum, setSum] = useState(amount);
    useEffect(() => {
        setSum(amount);
    }, [amount]);
    const handleCurrencyChange = useCallback((currency) => {
        setCurrencySelectOpen(false);
        onChange({ [currencyInputName]: currency });
    }, [currencyInputName, onChange]);
    const handleModalOpen = useCallback(() => {
        setCurrencySelectOpen(true);
    }, []);
    const handleModalClose = useCallback(() => {
        setCurrencySelectOpen(false);
    }, []);
    const isModalEnabled = useMemo(() => {
        return currencies.length > 1 || Array.isArray(currencies[0]);
    }, [currencies]);
    const onHandleInputChange = (value) => {
        onChange({ [amountInputName]: value });
    };
    return (<div className={styles.wrapper}>
      <p className={`${styles.label} ${error ? styles.label_error : ''}`}>
        {label}
      </p>
      <div className={`${styles.input_wrapper} ${error ? styles.input_wrapper_error : ''}`}>
        {skeleton ? (<InputSkeleton />) : (<NumberInput value={sum} onChange={onHandleInputChange} dataTestId={`${dataTestIdPrefix}-input`} precision={precision}/>)}
        <StyledButton disable={!isModalEnabled} data-test-id={`${dataTestIdPrefix}-button`} onClick={handleModalOpen}>
          {currency ? (<>
              <CurrencyIcon diameter={24} a3={currency.a3}/>
              <CurrencyCodeSpan>{currency.code}</CurrencyCodeSpan>
            </>) : (<span>Select</span>)}
          {isModalEnabled ? <ArrowIcon /> : null}
        </StyledButton>
      </div>
      <ErrorLine data-test-id={`${dataTestIdPrefix}-error`}>
        {!skeleton ? error : null}
      </ErrorLine>
      <CurrencyModal searchPlaceholder={searchPlaceholder} searchEnabled={searchEnabled} onChange={handleCurrencyChange} currencies={currencies} label={currencyModalLabel} onClose={handleModalClose} isOpen={currencySelectOpen} currency={currency} hideProtocol={hideProtocol} dataTestIdPrefix={dataTestIdPrefix}/>
    </div>);
};
