/** @type {import('@lingui/conf').LinguiConfig} */
module.exports = {
  locales: ['en', 'es', 'fr', 'de', 'pt', 'it', 'hu', 'et', 'pl', 'sk'],
  sourceLocale: 'en',
  catalogs: [
    {
      path: '<rootDir>/src/i18n/locales/{locale}.messages',
      include: ['src'],
    },
  ],
  format: 'po',
};
