import React, { createContext, useContext } from 'react';
import { invariant } from '../../utils/invariant';
import { useApplication } from './ApplicationContext';
const SettingContext = createContext(null);
export const SettingContextProvider = ({ settings, children, }) => {
    return (<SettingContext.Provider value={settings}>
      {children}
    </SettingContext.Provider>);
};
export function useSettings() {
    const settings = useContext(SettingContext);
    invariant(settings, 'Settings is not provide');
    return settings;
}
export function getNonEditableDefaultValue(v) {
    return v && v.value && v.editable === false ? v.value : undefined;
}
export function useNFT() {
    const { orderFacade: { NFTInfo }, } = useApplication();
    return NFTInfo || null;
}
