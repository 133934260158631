import { useTheme } from 'styled-components';
import React from 'react';
export const SupportIcon = () => {
    const theme = useTheme();
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4 1.02048C6.62 1.33048 3 5.52048 3 10.3105V17.0005C3 18.6605 4.34 20.0005 6 20.0005H7C8.1 20.0005 9 19.1005 9 18.0005V14.0005C9 12.9005 8.1 12.0005 7 12.0005H5V10.2905C5 6.45048 7.96 3.11048 11.79 3.00048C15.76 2.89048 19 6.06048 19 10.0005V12.0005H17C15.9 12.0005 15 12.9005 15 14.0005V18.0005C15 19.1005 15.9 20.0005 17 20.0005H19V21.0005H13C12.45 21.0005 12 21.4505 12 22.0005C12 22.5505 12.45 23.0005 13 23.0005H18C19.66 23.0005 21 21.6605 21 20.0005V10.0005C21 4.83048 16.64 0.680479 11.4 1.02048Z" fill={theme.colors.textBodyMain}/>
    </svg>);
};
export const ArrowIcon = ({ className }) => {
    const theme = useTheme();
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M10.0001 6L8.59009 7.41L13.1701 12L8.59009 16.59L10.0001 18L16.0001 12L10.0001 6Z" fill={theme.colors.textBodyMain}/>
    </svg>);
};
export const TermsSvg = () => {
    const theme = useTheme();
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5127_101698)">
        <path d="M8.595 12.812C8.46821 12.2784 8.46821 11.7226 8.595 11.189L7.603 10.616L8.603 8.884L9.595 9.457C9.99316 9.07988 10.4745 8.80172 11 8.645V7.5H13V8.645C13.532 8.803 14.012 9.085 14.405 9.457L15.397 8.884L16.397 10.616L15.405 11.189C15.5316 11.7223 15.5316 12.2777 15.405 12.811L16.397 13.384L15.397 15.116L14.405 14.543C14.0068 14.9201 13.5255 15.1983 13 15.355V16.5H11V15.355C10.4745 15.1983 9.99316 14.9201 9.595 14.543L8.603 15.116L7.603 13.384L8.595 12.812ZM12 13.5C12.3978 13.5 12.7794 13.342 13.0607 13.0607C13.342 12.7794 13.5 12.3978 13.5 12C13.5 11.6022 13.342 11.2206 13.0607 10.9393C12.7794 10.658 12.3978 10.5 12 10.5C11.6022 10.5 11.2206 10.658 10.9393 10.9393C10.658 11.2206 10.5 11.6022 10.5 12C10.5 12.3978 10.658 12.7794 10.9393 13.0607C11.2206 13.342 11.6022 13.5 12 13.5ZM15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992Z" fill={theme.colors.textBodyMain}/>
      </g>
      <defs>
        <clipPath id="clip0_5127_101698">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>);
};
export const PrivacySvg = () => {
    const theme = useTheme();
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992ZM12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.66304 14.2366 10.2989 13.7678 10.7678C13.2989 11.2366 12.663 11.5 12 11.5ZM7.527 17C7.64822 15.8984 8.17168 14.8803 8.99708 14.1407C9.82248 13.4012 10.8918 12.9922 12 12.9922C13.1082 12.9922 14.1775 13.4012 15.0029 14.1407C15.8283 14.8803 16.3518 15.8984 16.473 17H7.527Z" fill={theme.colors.textBodyMain}/>
    </svg>);
};
export const AMLSvg = () => {
    const theme = useTheme();
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 8V4H5V20H19V9H16V13.62C16 14.464 15.554 15.253 14.813 15.721L12 17.498L9.187 15.72C8.446 15.253 8 14.464 8 13.62V8H14ZM21 8V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3 2.455 3.449 2 4.002 2H14.997L21 8ZM10 13.62C10 13.77 10.087 13.924 10.255 14.03L12 15.132L13.745 14.03C13.913 13.924 14 13.77 14 13.62V10H10V13.62Z" fill={theme.colors.textBodyMain}/>
    </svg>);
};
export const CookieSvg = () => {
    const theme = useTheme();
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5127_101738)">
        <path d="M16 2L21 7V20.993C21 21.549 20.555 22 20.007 22H3.993C3.445 22 3 21.545 3 21.008V2.992C3 2.444 3.447 2 3.999 2H16ZM15 4H5V20H19V8H15V4ZM13 9V13H16V15H11V9H13Z" fill={theme.colors.textBodyMain}/>
      </g>
      <defs>
        <clipPath id="clip0_5127_101738">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>);
};
export const PerksSvg = () => {
    const theme = useTheme();
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5231_28674)">
        <path d="M22 20.8889C22 21.1836 21.8946 21.4662 21.7071 21.6746C21.5196 21.8829 21.2652 22 21 22H3C2.73478 22 2.48043 21.8829 2.29289 21.6746C2.10536 21.4662 2 21.1836 2 20.8889V3.11111C2 2.81643 2.10536 2.53381 2.29289 2.32544C2.48043 2.11706 2.73478 2 3 2H21C21.2652 2 21.5196 2.11706 21.7071 2.32544C21.8946 2.53381 22 2.81643 22 3.11111V20.8889ZM11 15.3333H4V19.7778H11V15.3333ZM20 10.8889H13V19.7778H20V10.8889ZM11 4.22222H4V13.1111H11V4.22222ZM20 4.22222H13V8.66667H20V4.22222Z" fill={theme.colors.textBodyMain}/>
      </g>
      <defs>
        <clipPath id="clip0_5231_28674">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>);
};
export const LanguageIcon = () => {
    const theme = useTheme();
    return (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" fill={theme.colors.textBodyMain}/>
    </svg>);
};
