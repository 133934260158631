import React from 'react';
import styled from 'styled-components';
const StyledButton = styled.button `
  padding: 20px 32px;
  border-radius: 8px;
  background: ${(props) => props.variant === 'primary'
    ? props.theme.colors.main
    : props.theme.colors.btnSecondDefault};
  color: ${(props) => props.variant === 'primary'
    ? props.theme.colors.textBtn
    : props.theme.colors.main};
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  border: none;

  &:hover {
    background: ${(props) => props.variant === 'primary'
    ? props.theme.colors.btnBodyHover
    : props.theme.colors.btnSecondHover};
  }

  &:active {
    background: ${(props) => props.variant === 'primary'
    ? props.theme.colors.btnBodyPressed
    : props.theme.colors.btnSecondPressed};
    border: none;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.4;
  }

  &:focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.mainFocus};
  }
`;
const LoaderCircle = styled.div `
  margin-left: 12px;
  width: 20px;
  height: 20px;

  border-radius: 50%;
  border: 2px solid
    ${(props) => props.variant === 'primary'
    ? props.theme.colors.textBtn
    : props.theme.colors.main};

  border-color: ${(props) => props.variant === 'primary'
    ? props.theme.colors.textBtn
    : props.theme.colors.main}
    transparent
    ${(props) => props.variant === 'primary'
    ? props.theme.colors.textBtn
    : props.theme.colors.main}
    transparent;
  animation: lds-dual-ring 1.2s linear infinite;

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const Button = ({ label, onClick, variant = 'primary', disabled, className, dataTestId, loader, }) => {
    return (<StyledButton className={className} variant={variant} onClick={onClick} disabled={disabled} data-test-id={dataTestId}>
      {label}
      {loader ? <LoaderCircle variant={variant}/> : null}
    </StyledButton>);
};
