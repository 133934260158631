import styles from './Tooltip.module.css';
import React, { useEffect, useRef, useState } from 'react';
const HALF_MAIN_CONTENT_PADDING = 16; //px
const MAXIMUM_POSSIBLE_TOOLTIP_WIDTH = 336; //px
const calculateElementPosition = (offset) => {
    return `calc(50% + ${offset}px)`;
};
export const Tooltip = ({ visibleState, children, content, onClick, width, position = 'top', defaultVisible = false, top, }) => {
    const [isVisible, setIsVisible] = useState(defaultVisible);
    const tooltipRef = useRef(null);
    const cornerRef = useRef(null);
    useEffect(() => {
        const tooltipEl = tooltipRef.current;
        const cornerEl = cornerRef.current;
        if (tooltipEl && cornerEl) {
            const bodyWidth = document.body.clientWidth;
            const { left, right, width } = tooltipEl.getBoundingClientRect();
            if (width > MAXIMUM_POSSIBLE_TOOLTIP_WIDTH) {
                tooltipEl.style.width = `${MAXIMUM_POSSIBLE_TOOLTIP_WIDTH}px`;
                tooltipEl.style.whiteSpace = 'wrap';
            }
            if (left < 0) {
                const tooltipElOffset = HALF_MAIN_CONTENT_PADDING - left;
                const cornerElOffset = left - HALF_MAIN_CONTENT_PADDING;
                tooltipEl.style.left = calculateElementPosition(tooltipElOffset);
                cornerEl.style.left = calculateElementPosition(cornerElOffset);
            }
            if (right > bodyWidth) {
                const tooltipElOffset = -HALF_MAIN_CONTENT_PADDING - (right - bodyWidth);
                const cornerElOffset = HALF_MAIN_CONTENT_PADDING + (right - bodyWidth);
                tooltipEl.style.left = calculateElementPosition(tooltipElOffset);
                cornerEl.style.left = calculateElementPosition(cornerElOffset);
            }
        }
    }, [isVisible, visibleState]);
    const tooltipVisible = isVisible || visibleState ? styles.tooltip__component_type_visible : '';
    const calculateComponentClassName = `${styles.tooltip__component} ${styles[`tooltip__component_type_${position}`]} ${tooltipVisible}`;
    const calculateCornerClassName = `${styles.tooltip__corner} ${styles[`tooltip__corner_type_${position}`]}`;
    return (<div className={styles.tooltip__wrapper} onMouseOver={onClick ? undefined : () => setIsVisible(true)} onMouseOut={onClick ? undefined : () => setIsVisible(false)} onClick={onClick}>
      {children}
      <div className={calculateComponentClassName} ref={tooltipRef} style={{
            width: width,
            whiteSpace: `${width ? 'normal' : 'nowrap'}`,
            top,
        }}>
        {content}
        <div className={calculateCornerClassName} ref={cornerRef}/>
      </div>
    </div>);
};
