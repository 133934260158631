export const ONLY_DIGITS_COMMA_OR_DOT_REGEXP = /^[\d,.]*$/;
const ONLY_DIGITS_REGEXP = /^\d*$/;
export const removeLastCharacter = (value) => {
    return value.slice(0, -1);
};
export const isMultipleCommas = (value) => {
    return value.split('.').length > 2;
};
export const isFractionTooLong = (value, precision) => {
    const fraction = value.split('.')[1];
    return fraction && precision && fraction.length > precision;
};
export const isInvalidCharacterAfterZero = (value) => {
    return (value.startsWith('0') &&
        value.length === 2 &&
        !(value.charAt(1) === ',' || value.charAt(1) === '.'));
};
export const handleInvalidCharacterAfterZero = (value) => {
    if (ONLY_DIGITS_REGEXP.test(value)) {
        const lastNumber = value.at(-1);
        return lastNumber === '0' ? '0' : lastNumber;
    }
    return removeLastCharacter(value);
};
export const isCommaEntered = (value) => {
    return value.at(-1) === ',';
};
export const handleCommaEntered = (value) => {
    return value.includes('.')
        ? removeLastCharacter(value)
        : value.replace(',', '.');
};
export const shouldAutoPrefixZero = (value) => {
    return value.startsWith(',') || value.startsWith('.');
};
