var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { createRoot } from 'react-dom/client';
import { PageManager } from './PageManager';
import { ContextWrapper } from './Contexts/ContextWrapper';
import { ThemeWrapper } from './Styles/ThemeWrapper';
import ErrorPage from './Pages/ErrorPage/ErrorPage';
import './Styles/index.css';
import { I18nProvider } from '@lingui/react';
import { loadAndInitLocaleAsync } from '../i18n';
export function renderUI(container, settings, appData) {
    return __awaiter(this, void 0, void 0, function* () {
        const root = createRoot(container);
        const i18n = yield loadAndInitLocaleAsync();
        const [application, currencies, merchant] = appData;
        root.render(<React.StrictMode>
      <I18nProvider i18n={i18n}>
        <ThemeWrapper customColors={settings === null || settings === void 0 ? void 0 : settings.colors}>
          <ContextWrapper location={merchant.geo} merchant={merchant} settings={settings} application={application} currencies={currencies}>
            <PageManager />
          </ContextWrapper>
        </ThemeWrapper>
      </I18nProvider>
    </React.StrictMode>);
    });
}
export function renderError(container, settings, errorCode) {
    return __awaiter(this, void 0, void 0, function* () {
        const root = createRoot(container);
        const i18n = yield loadAndInitLocaleAsync();
        root.render(<React.StrictMode>
      <I18nProvider i18n={i18n}>
        <ThemeWrapper customColors={settings === null || settings === void 0 ? void 0 : settings.colors}>
          <ErrorPage errorCode={errorCode}/>
        </ThemeWrapper>
      </I18nProvider>
    </React.StrictMode>);
    });
}
