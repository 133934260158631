import React, { createContext, useContext } from 'react';
const LocationDataContext = createContext(undefined);
export const LocationDataProvider = ({ children, locationData, }) => {
    return (<LocationDataContext.Provider value={locationData}>
      {children}
    </LocationDataContext.Provider>);
};
export function useLocationData() {
    return useContext(LocationDataContext);
}
