var StorageKeys;
(function (StorageKeys) {
    StorageKeys["TOKEN"] = "TOKEN";
    StorageKeys["ORDER_ID"] = "ORDER_ID";
    StorageKeys["USER_UID"] = "USER_UID";
    StorageKeys["API_KEY"] = "API_KEY";
})(StorageKeys || (StorageKeys = {}));
class StorageItem {
    constructor(key) {
        this.key = key;
        try {
            this.value = (sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem(this.key)) || undefined;
            // eslint-disable-next-line no-empty
        }
        catch (e) { }
    }
    get() {
        return this.value;
    }
    set(value) {
        try {
            sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.setItem(this.key, value);
            // eslint-disable-next-line no-empty
        }
        catch (e) {
        }
        finally {
            this.value = value;
        }
    }
    remove() {
        try {
            sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.removeItem(this.key);
            // eslint-disable-next-line no-empty
        }
        catch (e) {
        }
        finally {
            this.value = undefined;
        }
    }
}
const storagesFromKeys = Object.keys(StorageKeys).reduce((previousValue, currentValue) => {
    const key = currentValue;
    previousValue[key] = new StorageItem(key);
    return previousValue;
}, {});
const createStorage = () => {
    const clearAll = () => {
        Object.keys(storagesFromKeys).forEach((key) => {
            storagesFromKeys[key].remove();
        });
    };
    return Object.assign(Object.assign({}, storagesFromKeys), { clearAll });
};
export const storage = createStorage();
