import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import styled from 'styled-components';
import { CurrencyIcon } from '../BaseComponents/CurrencyIcon';
import { StyledButton } from './index';
import { ArrowIcon } from '../Icons/ArrowIcon';
export const OptionItem = styled.div `
  cursor: pointer;
  display: flex;
  padding: 20px 32px;
  align-items: center;

  &:hover {
    background: ${(props) => props.theme.colors.dieDefault};
  }
`;
const CurrencyCodeLabel = styled.div `
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-left: 8px;
  margin-right: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 80px;
  color: ${(props) => props.theme.colors.textBodyMain};
`;
const CurrencyBtnSpan = styled.span `
  max-width: 70px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const CustomCheckBox = styled.div `
  width: 16px;
  height: 16px;
  border: 2px solid ${(props) => props.theme.colors.textBodyMain};
  border-radius: 50%;
  position: relative;

  &::after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.isActive ? props.theme.colors.textBodyMain : 'transparent'};
    position: absolute;
    top: 3px;
    left: 3px;
  }
`;
const CurrencyCodeFullName = styled.div `
  color: ${(props) => props.theme.colors.textBodyAdd};
  font-size: 18px;
  line-height: 24px;
  flex: 1;
  font-weight: ${(props) => (props.isActive ? 600 : 400)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const CurrencyNetwork = styled.div `
  color: ${(props) => props.theme.colors.textBodyAdd};
  font-weight: ${(props) => (props.isActive ? 600 : 400)};
  font-size: 18px;
  line-height: 24px;
  width: 77px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ArrowWrapper = styled.div `
  display: flex;
  & > svg {
    transform: ${(props) => props.isActive ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`;
export const CurrencyOption = ({ onCurrencySelect, currency, selectedCurrency, hideProtocol, dataTestIdPrefix, }) => {
    const handleCurrencySelect = useCallback((item) => {
        onCurrencySelect(item);
    }, [onCurrencySelect]);
    if (Array.isArray(currency)) {
        return (<MultipleCurrencyOption currencies={currency} selectedCurrency={selectedCurrency} onClick={handleCurrencySelect} dataTestIdPrefix={dataTestIdPrefix}/>);
    }
    return (<SingleCurrencyOption hideProtocol={hideProtocol} currency={currency} onClick={handleCurrencySelect} dataTestIdPrefix={dataTestIdPrefix}/>);
};
const MultipleCurrencyOption = ({ currencies, selectedCurrency, onClick, dataTestIdPrefix, }) => {
    const [isListOpen, setIsListOpen] = useState(false);
    const mainCurrency = useMemo(() => currencies.find((item) => item.a3 === selectedCurrency.a3) ||
        currencies[0], [currencies, selectedCurrency]);
    useEffect(() => {
        setIsListOpen(false);
    }, [selectedCurrency]);
    const handleWrapperClick = useCallback(() => {
        onClick(mainCurrency);
    }, [onClick, mainCurrency]);
    const openList = useCallback((e) => {
        e.stopPropagation();
        setIsListOpen((flag) => !flag);
    }, [setIsListOpen]);
    return (<div>
      <OptionItem data-test-id={`item-multi-${dataTestIdPrefix}-${mainCurrency.code}`} onClick={handleWrapperClick}>
        <CurrencyIcon diameter={32} a3={mainCurrency.a3}/>
        <CurrencyCodeLabel>{mainCurrency.code}</CurrencyCodeLabel>
        <CurrencyCodeFullName>{mainCurrency.name}</CurrencyCodeFullName>
        <StyledButton data-test-id="button-open-currencies" style={{ padding: '4px 10px', fontSize: 18 }} onClick={openList}>
          <CurrencyBtnSpan style={{ marginRight: 6 }}>
            {mainCurrency.protocol}
          </CurrencyBtnSpan>
          <ArrowWrapper isActive={isListOpen}>
            <ArrowIcon />
          </ArrowWrapper>
        </StyledButton>
      </OptionItem>
      <div style={{ display: isListOpen ? 'block' : 'none' }}>
        {currencies.map((item) => {
            const isActive = mainCurrency.a3 === item.a3;
            return (<OptionItem data-test-id={`item-${item.a3}`} onClick={() => onClick(item)} key={item.a3}>
              <div style={{
                    width: 32,
                    marginRight: 19,
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                <CustomCheckBox isActive={isActive}/>
              </div>
              <CurrencyCodeFullName isActive={isActive}>
                {item.blockchainName}
              </CurrencyCodeFullName>
              <CurrencyNetwork isActive={isActive}>
                {item.protocol}
              </CurrencyNetwork>
            </OptionItem>);
        })}
      </div>
    </div>);
};
const SingleCurrencyOption = ({ currency, onClick, hideProtocol, dataTestIdPrefix, }) => {
    const handleClick = useCallback(() => {
        onClick(currency);
    }, [currency, onClick]);
    return (<OptionItem data-test-id={`item-${dataTestIdPrefix}-${currency.code}`} onClick={handleClick}>
      <CurrencyIcon diameter={32} a3={currency.a3}/>
      <CurrencyCodeLabel>{currency.code}</CurrencyCodeLabel>
      <CurrencyCodeFullName>{currency.name}</CurrencyCodeFullName>
      {hideProtocol ? null : (<StyledButton style={{ padding: '4px 10px', fontSize: 18 }} disable>
          <CurrencyBtnSpan>
            {currency.protocol && currency.protocol.toLowerCase() !== 'native'
                ? currency.protocol
                : currency.code}
          </CurrencyBtnSpan>
        </StyledButton>)}
    </OptionItem>);
};
