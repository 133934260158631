import styles from './Footer.module.css';
import React, { forwardRef } from 'react';
import { Button } from '../BaseComponents/Button';
import { Tooltip } from '../BaseComponents/Tooltip';
import { Caption } from './Caption';
export const Footer = forwardRef(function Footer({ withCaption = true, buttonClick, actionButtonLabel, secondaryButtonDisabled, secondaryButtonLabel, onSecondaryButtonClick, actionBtnError, actionButtonDisable, actionButtonLoader, secondaryButtonLoader, }, ref) {
    return (<footer ref={ref} className={`${styles.wrapper} ${withCaption ? styles.wrapper_with_caption : ''}`}>
      {secondaryButtonLabel && (<Button dataTestId="secondary-button" disabled={secondaryButtonDisabled} variant="secondary" label={secondaryButtonLabel} onClick={onSecondaryButtonClick} loader={secondaryButtonLoader}/>)}
      {actionButtonLabel &&
            (actionBtnError ? (<Tooltip content={actionBtnError} visibleState={Boolean(actionBtnError)}>
            <Button disabled={actionButtonDisable} dataTestId="primary-button" onClick={buttonClick} label={actionButtonLabel} loader={actionButtonLoader}/>
          </Tooltip>) : (<Button disabled={actionButtonDisable} dataTestId="primary-button" onClick={buttonClick} label={actionButtonLabel} loader={actionButtonLoader}/>))}
      {withCaption && <Caption />}
    </footer>);
});
