export var InitializationErrorsCode;
(function (InitializationErrorsCode) {
    InitializationErrorsCode[InitializationErrorsCode["WrongApiKey"] = 10] = "WrongApiKey";
    InitializationErrorsCode[InitializationErrorsCode["UnknowingError"] = 11] = "UnknowingError";
    InitializationErrorsCode[InitializationErrorsCode["NoOneCurrencyPair"] = 12] = "NoOneCurrencyPair";
    InitializationErrorsCode[InitializationErrorsCode["BlockedUser"] = 13] = "BlockedUser";
    InitializationErrorsCode[InitializationErrorsCode["BlockedGeo"] = 14] = "BlockedGeo";
})(InitializationErrorsCode || (InitializationErrorsCode = {}));
export class InitializationError {
    constructor({ code }) {
        this.code = code;
    }
    get errorCode() {
        return this.code;
    }
}
