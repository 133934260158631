import React from 'react';
export function English() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_4_7321)">
        <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#F0F0F0"/>
        <path d="M3.311 6.25879C2.05419 7.89398 1.1065 9.77866 0.554688 11.8261H8.87831L3.311 6.25879Z" fill="#0052B4"/>
        <path d="M31.4485 11.826C30.8967 9.77866 29.9489 7.89398 28.6922 6.25879L23.125 11.826H31.4485Z" fill="#0052B4"/>
        <path d="M0.554688 20.1743C1.10656 22.2217 2.05425 24.1064 3.311 25.7415L8.87813 20.1743H0.554688Z" fill="#0052B4"/>
        <path d="M25.7469 3.30764C24.1117 2.05083 22.2271 1.10314 20.1797 0.55127V8.87483L25.7469 3.30764Z" fill="#0052B4"/>
        <path d="M6.26562 28.6926C7.90081 29.9494 9.7855 30.8971 11.8329 31.449V23.1255L6.26562 28.6926Z" fill="#0052B4"/>
        <path d="M11.8328 0.55127C9.78544 1.10314 7.90075 2.05083 6.26562 3.30758L11.8328 8.87477V0.55127Z" fill="#0052B4"/>
        <path d="M20.1797 31.449C22.2271 30.8971 24.1118 29.9494 25.7469 28.6927L20.1797 23.1255V31.449Z" fill="#0052B4"/>
        <path d="M23.125 20.1738L28.6922 25.7411C29.9489 24.106 30.8967 22.2212 31.4485 20.1738H23.125Z" fill="#0052B4"/>
        <path d="M31.8646 13.9131H18.0871H18.087V0.135438C17.4038 0.0465 16.7073 0 16 0C15.2926 0 14.5962 0.0465 13.9131 0.135438V13.9129V13.913H0.135438C0.0465 14.5962 0 15.2927 0 16C0 16.7074 0.0465 17.4038 0.135438 18.0869H13.9129H13.913V31.8646C14.5962 31.9535 15.2926 32 16 32C16.7073 32 17.4038 31.9536 18.0869 31.8646V18.0871V18.087H31.8646C31.9535 17.4038 32 16.7074 32 16C32 15.2927 31.9535 14.5962 31.8646 13.9131Z" fill="#D80027"/>
        <path d="M20.1719 20.1739L27.3116 27.3136C27.64 26.9854 27.9532 26.6422 28.2521 26.2865L22.1395 20.1738H20.1719V20.1739Z" fill="#D80027"/>
        <path d="M11.8273 20.1738H11.8272L4.6875 27.3135C5.01575 27.6419 5.35894 27.9551 5.71469 28.254L11.8273 22.1413V20.1738Z" fill="#D80027"/>
        <path d="M11.8224 11.8265V11.8263L4.68269 4.68652C4.35431 5.01477 4.04106 5.35796 3.74219 5.71371L9.85487 11.8264H11.8224V11.8265Z" fill="#D80027"/>
        <path d="M20.1719 11.8264L27.3117 4.68653C26.9834 4.35816 26.6403 4.04491 26.2845 3.74609L20.1719 9.85878V11.8264Z" fill="#D80027"/>
      </g>
      <defs>
        <clipPath id="clip0_4_7321">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>);
}
export function Spanish() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_4_7292)">
        <path d="M0 16C0 17.9571 0.352063 19.8318 0.995188 21.5651L16 22.9565L31.0048 21.5651C31.6479 19.8318 32 17.9571 32 16C32 14.0428 31.6479 12.1681 31.0048 10.4348L16 9.04346L0.995188 10.4348C0.352063 12.1681 0 14.0428 0 16H0Z" fill="#FFDA44"/>
        <path d="M31.0057 10.4348C28.745 4.34206 22.8803 0 16.0009 0C9.12147 0 3.25678 4.34206 0.996094 10.4348H31.0057Z" fill="#D80027"/>
        <path d="M0.996094 21.5654C3.25678 27.6582 9.12147 32.0002 16.0009 32.0002C22.8803 32.0002 28.745 27.6582 31.0057 21.5654H0.996094Z" fill="#D80027"/>
      </g>
      <defs>
        <clipPath id="clip0_4_7292">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>);
}
export function French() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_4_7156)">
        <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#F0F0F0"/>
        <path d="M31.9973 15.9999C31.9973 9.12049 27.6553 3.2558 21.5625 0.995117V31.0048C27.6553 28.7441 31.9973 22.8794 31.9973 15.9999Z" fill="#D80027"/>
        <path d="M0 15.9999C0 22.8794 4.34206 28.7441 10.4348 31.0047V0.995117C4.34206 3.2558 0 9.12049 0 15.9999Z" fill="#0052B4"/>
      </g>
      <defs>
        <clipPath id="clip0_4_7156">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>);
}
export function German() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_4_7162)">
        <path d="M0.996094 21.5651C3.25678 27.6578 9.12147 31.9999 16.0009 31.9999C22.8803 31.9999 28.745 27.6578 31.0057 21.5651L16.0009 20.1738L0.996094 21.5651Z" fill="#FFDA44"/>
        <path d="M16.0009 0C9.12147 0 3.25678 4.342 0.996094 10.4348L16.0009 11.8261L31.0057 10.4347C28.745 4.342 22.8803 0 16.0009 0Z" fill="black"/>
        <path d="M0.995188 10.4346C0.352063 12.1679 0 14.0426 0 15.9998C0 17.9569 0.352063 19.8316 0.995188 21.5649H31.0049C31.648 19.8316 32 17.9569 32 15.9998C32 14.0426 31.648 12.1679 31.0048 10.4346H0.995188Z" fill="#D80027"/>
      </g>
      <defs>
        <clipPath id="clip0_4_7162">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>);
}
export function Portuguese() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_4_7259)">
        <path d="M0 15.9999C0 22.8793 4.342 28.744 10.4347 31.0047L11.8261 15.9999L10.4347 0.995117C4.342 3.25587 0 9.12049 0 15.9999Z" fill="#6DA544"/>
        <path d="M32.0028 16C32.0028 7.1635 24.8393 0 16.0028 0C14.0457 0 12.1709 0.352063 10.4375 0.995188V31.0048C12.1709 31.648 14.0457 32 16.0028 32C24.8393 32 32.0028 24.8365 32.0028 16Z" fill="#D80027"/>
        <path d="M10.4324 21.5649C13.5059 21.5649 15.9976 19.0733 15.9976 15.9998C15.9976 12.9262 13.5059 10.4346 10.4324 10.4346C7.35881 10.4346 4.86719 12.9262 4.86719 15.9998C4.86719 19.0733 7.35881 21.5649 10.4324 21.5649Z" fill="#FFDA44"/>
        <path d="M7.30469 13.2173V16.6955C7.30469 18.4244 8.70619 19.826 10.4351 19.826C12.1641 19.826 13.5656 18.4245 13.5656 16.6955V13.2173H7.30469Z" fill="#D80027"/>
        <path d="M10.4341 17.7389C9.85875 17.7389 9.39062 17.2708 9.39062 16.6954V15.3042H11.4776V16.6955C11.4776 17.2708 11.0094 17.7389 10.4341 17.7389Z" fill="#F0F0F0"/>
      </g>
      <defs>
        <clipPath id="clip0_4_7259">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>);
}
export function Hungarian() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_4_7178)">
        <path d="M0.995188 10.4348C0.352063 12.1681 0 14.0428 0 16C0 17.9571 0.352063 19.8319 0.995188 21.5651L16 22.9565L31.0048 21.5651C31.6479 19.8319 32 17.9571 32 16C32 14.0428 31.6479 12.1681 31.0048 10.4348L16 9.04346L0.995188 10.4348Z" fill="#F0F0F0"/>
        <path d="M15.9969 0C9.11756 0 3.25281 4.342 0.992188 10.4348H31.0019C28.7411 4.342 22.8764 0 15.9969 0Z" fill="#D80027"/>
        <path d="M15.9969 32.0002C22.8764 32.0002 28.7411 27.6582 31.0018 21.5654H0.992188C3.25281 27.6582 9.11756 32.0002 15.9969 32.0002Z" fill="#6DA544"/>
      </g>
      <defs>
        <clipPath id="clip0_4_7178">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>);
}
export function Estonian() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_4_7149)">
        <path d="M0 16.0001C0 17.9573 0.352063 19.832 0.995188 21.5653L16 22.261L31.0048 21.5654C31.648 19.832 32 17.9573 32 16.0001C32 14.043 31.648 12.1683 31.0048 10.4349L16 9.73926L0.995188 10.4349C0.352063 12.1683 0 14.043 0 16.0001H0Z" fill="black"/>
        <path d="M15.997 0C9.11756 0 3.25288 4.342 0.992188 10.4348H31.0019C28.7411 4.342 22.8764 0 15.997 0Z" fill="#0052B4"/>
        <path d="M31.0018 21.5654H0.992188C3.25288 27.6582 9.11756 32.0002 15.997 32.0002C22.8764 32.0002 28.7411 27.6582 31.0018 21.5654Z" fill="#F0F0F0"/>
      </g>
      <defs>
        <clipPath id="clip0_4_7149">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>);
}
export function Slovak() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_4_7284)">
        <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#F0F0F0"/>
        <path d="M16 31.9998C24.8365 31.9998 32 24.8363 32 15.9998C32 14.0426 31.648 12.1678 31.0048 10.4346H0.995188C0.352063 12.1678 0 14.0426 0 15.9998C0 24.8363 7.1635 31.9998 16 31.9998Z" fill="#0052B4"/>
        <path d="M15.997 32.0002C22.8764 32.0002 28.7411 27.6582 31.0018 21.5654H0.992188C3.25288 27.6582 9.11756 32.0002 15.997 32.0002Z" fill="#D80027"/>
        <path d="M4.14062 9.04346V17.0251C4.14062 21.5653 10.0719 22.9565 10.0719 22.9565C10.0719 22.9565 16.0032 21.5653 16.0032 17.0251V9.04346H4.14062Z" fill="#F0F0F0"/>
        <path d="M5.53125 9.04346V17.0251C5.53125 17.5581 5.64963 18.0615 5.88413 18.5334H14.2583C14.4928 18.0615 14.6112 17.5581 14.6112 17.0251V9.04346H5.53125Z" fill="#D80027"/>
        <path d="M12.8542 14.6084H10.7674V13.2171H12.1587V11.8258H10.7674V10.4346H9.37606V11.8258H7.98481V13.2171H9.37606V14.6084H7.28906V15.9998H9.37606V17.3911H10.7674V15.9998H12.8542V14.6084Z" fill="#F0F0F0"/>
        <path d="M7.7805 20.4757C8.67706 21.0392 9.59819 21.3663 10.0697 21.5118C10.5413 21.3664 11.4624 21.0392 12.359 20.4757C13.2631 19.9075 13.8969 19.2576 14.2568 18.5336C13.8599 18.2527 13.3756 18.0871 12.8524 18.0871C12.6619 18.0871 12.4768 18.1097 12.2989 18.1511C11.9218 17.2944 11.0659 16.6958 10.0698 16.6958C9.07375 16.6958 8.21775 17.2944 7.84075 18.1511C7.66281 18.1097 7.47769 18.0871 7.28725 18.0871C6.76406 18.0871 6.27975 18.2527 5.88281 18.5336C6.2425 19.2576 6.87631 19.9074 7.7805 20.4757Z" fill="#0052B4"/>
      </g>
      <defs>
        <clipPath id="clip0_4_7284">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>);
}
export function Italian() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_4_7187)">
        <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#F0F0F0"/>
        <path d="M31.9973 15.9999C31.9973 9.12049 27.6553 3.2558 21.5625 0.995117V31.0048C27.6553 28.7441 31.9973 22.8794 31.9973 15.9999Z" fill="#D80027"/>
        <path d="M0 15.9999C0 22.8794 4.342 28.7441 10.4348 31.0047V0.995117C4.342 3.2558 0 9.12049 0 15.9999Z" fill="#6DA544"/>
      </g>
      <defs>
        <clipPath id="clip0_4_7187">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>);
}
export function Polish() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_4_7258)">
        <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#F0F0F0"/>
        <path d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16" fill="#D80027"/>
      </g>
      <defs>
        <clipPath id="clip0_4_7258">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>);
}
