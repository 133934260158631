import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { loadAndInitLocaleAsync } from '../../../i18n';
import { OptionItem } from '../CurrencyInput/CurrencyOption';
import { ListModal } from '../Modals/ListModal';
import { English, Portuguese, Spanish } from './Icons';
import styles from './LanguageSelector.module.css';
import { useAnalytics } from '../../Contexts/AnalyticsContext';
import { useApplication } from '../../Contexts/ApplicationContext';
const locales = [
    {
        code: 'en',
        originName: 'English',
        tranlatedName: msg `English`,
        flag: <English />,
    },
    {
        code: 'es',
        originName: 'Español',
        tranlatedName: msg `Spanish`,
        flag: <Spanish />,
    },
    // {
    //   code: 'fr',
    //   originName: 'Français',
    //   tranlatedName: msg`French`,
    //   flag: <French />,
    // },
    // {
    //   code: 'de',
    //   originName: 'Deutsch',
    //   tranlatedName: msg`German`,
    //   flag: <German />,
    // },
    {
        code: 'pt',
        originName: 'Português',
        tranlatedName: msg `Portuguese`,
        flag: <Portuguese />,
    },
    // {
    //   code: 'it',
    //   originName: 'Italiano',
    //   tranlatedName: msg`Italian`,
    //   flag: <Italian />,
    // },
    // {
    //   code: 'hu',
    //   originName: 'Magyar',
    //   tranlatedName: msg`Hungarian`,
    //   flag: <Hungarian />,
    // },
    // {
    //   code: 'et',
    //   originName: 'Eesti',
    //   tranlatedName: msg`Estonian`,
    //   flag: <Estonian />,
    // },
    // {
    //   code: 'pl',
    //   originName: 'Polski',
    //   tranlatedName: msg`Polish`,
    //   flag: <Polish />,
    // },
    // {
    //   code: 'sk',
    //   originName: 'Slovenský',
    //   tranlatedName: msg`Slovak`,
    //   flag: <Slovak />,
    // },
];
export function LanguageSelectorModal({ isOpen, onClose }) {
    const { i18n, _ } = useLingui();
    const { track } = useAnalytics();
    const { stepController } = useApplication();
    const handleLanguageChange = useCallback((localeCode) => {
        track('widget_language_changed', {
            step: stepController.currentStep.step,
            old_locale: i18n.locale,
            new_locale: localeCode,
        });
        localStorage.setItem('lang', localeCode);
        loadAndInitLocaleAsync(localeCode);
        onClose();
    }, [i18n.locale, onClose, stepController.currentStep.step, track]);
    const renderLocaleItem = useCallback((locale) => (<OptionItem key={locale.code} onClick={() => handleLanguageChange(locale.code)} data-test-id={`item-language-${locale.code}`}>
        {locale.flag}
        <div className={styles.locale_container}>
          <span className={styles.locale_origin_name}>{locale.originName}</span>
          <span className={styles.locale_translated_name}>
            {_(locale.tranlatedName)}
          </span>
        </div>
      </OptionItem>), [_, handleLanguageChange]);
    return (<ListModal onClose={onClose} label={_(msg `Select a language`)} isOpen={isOpen} list={locales} renderItems={renderLocaleItem} dataTestIdPrefix="language-selector-menu-item"/>);
}
export function LanguageSelectorButton({ onClick }) {
    const { i18n, _ } = useLingui();
    const selectedLocale = i18n.locale;
    const selectedLocaleData = locales.find((locale) => locale.code === selectedLocale);
    return (<div className={styles.trigger} title={_(msg `Select a language`)} data-test-id="button-open-language-selector" onClick={onClick}>
      {selectedLocaleData === null || selectedLocaleData === void 0 ? void 0 : selectedLocaleData.flag}
    </div>);
}
