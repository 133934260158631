import { FeatureFlag, useFeatureFlag, } from '../../../Contexts/FeatureFlagContext';
import styles from './Burger.module.css';
import React from 'react';
export const Burger = ({ onClick }) => {
    const enableWhiteLabel = useFeatureFlag(FeatureFlag.ENABLE_WHITELABEL);
    if (enableWhiteLabel) {
        return null;
    }
    return (<div className={styles.burger} data-test-id="button-menu-open" onClick={onClick}/>);
};
