import React, { createContext, useContext } from 'react';
import { invariant } from '../../utils/invariant';
const MerchantContext = createContext(undefined);
export const MerchantContextProvider = ({ merchant, children, }) => {
    return (<MerchantContext.Provider value={merchant}>
      {children}
    </MerchantContext.Provider>);
};
export function useMerchant() {
    const merchant = useContext(MerchantContext);
    invariant(merchant, 'Merchant is not defined');
    return merchant;
}
