import { action, makeObservable, observable } from 'mobx';
export class UserController {
    constructor({ phone, address, email, subscribe, } = {}) {
        this.phone = phone ? (phone[0] === '+' ? phone : `+${phone}`) : undefined;
        this.address = address;
        this.email = email;
        this.subscribe = subscribe || false;
        makeObservable(this, {
            phone: observable,
            address: observable,
            email: observable,
            subscribe: observable,
            setField: action,
        });
    }
    setField(value, fieldName) {
        if (fieldName === 'subscribe') {
            this.subscribe = !!value;
            return;
        }
        this[fieldName] = value;
    }
}
