import { action, computed, makeObservable, observable } from 'mobx';
export var PaymentCardType;
(function (PaymentCardType) {
    PaymentCardType["VISA"] = "Visa";
    PaymentCardType["MASTERCARD"] = "MasterCard";
})(PaymentCardType || (PaymentCardType = {}));
export class PaymentController {
    constructor() {
        this.setPayment = (type) => {
            this.type = type;
        };
        this.type = PaymentCardType.MASTERCARD;
        makeObservable(this, {
            type: observable,
            setPayment: action,
            paymentForService: computed,
        });
    }
    get paymentForService() {
        return this.type.toUpperCase();
    }
}
