import React from 'react';
import styled from 'styled-components';
const Loader = styled.div `
  height: 100%;
  background-color: ${(props) => props.theme.colors.background};

  &::after {
    content: '';
    width: 50px;
    height: 50px;
    border: 8px solid ${(props) => props.theme.colors.main};
    border-color: ${(props) => props.theme.colors.main} transparent
      ${(props) => props.theme.colors.main} transparent;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
    position: absolute;
    top: calc(50% - 45px);
    right: calc(50% - 25px);
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const Loading = () => {
    return <Loader />;
};
