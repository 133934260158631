import React, { createContext, useContext, useEffect, useState, } from 'react';
import { getCountriesWithPhone, } from '../../Services/methods/countriesWithPhone';
const CountriesWithPhoneContext = createContext(undefined);
export const CountriesWithPhoneProvider = ({ children }) => {
    const [countriesWithPhone, setCountriesWithPhone] = useState();
    useEffect(() => {
        getCountriesWithPhone().then((res) => setCountriesWithPhone(res));
    }, []);
    return (<CountriesWithPhoneContext.Provider value={countriesWithPhone}>
      {children}
    </CountriesWithPhoneContext.Provider>);
};
export function useCountriesWithPhone() {
    return useContext(CountriesWithPhoneContext);
}
