import React from 'react';
import { useTheme } from 'styled-components';
export const CaptionPaymentMethods = () => {
    const theme = useTheme();
    return (<svg width="95" height="12" viewBox="0 0 95 12" fill={theme.colors.textBodySw} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.0864 10.7686c2.6653 0 4.826-2.13499 4.826-4.76858 0-2.6336-2.1607-4.76854-4.826-4.76854-1.19442 0-2.28751.42876-3.13021 1.13903-.84269-.71026-1.93578-1.13903-3.13022-1.13903C2.16066 1.23148 0 3.36643 0 6.00002s2.16066 4.76858 4.82597 4.76858c1.19444 0 2.28753-.4288 3.13023-1.13907.8427.71027 1.93579 1.13907 3.1302 1.13907Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.95618 9.62952c1.03771-.87464 1.69573-2.17614 1.69573-3.6295 0-1.45337-.65802-2.75487-1.69573-3.62951.84269-.71026 1.93579-1.13903 3.13022-1.13903 2.6653 0 4.826 2.13494 4.826 4.76854 0 2.63359-2.1607 4.76858-4.826 4.76858-1.19443 0-2.28753-.4288-3.13022-1.13908Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.95623 9.62955c1.03772-.87464 1.69573-2.17614 1.69573-3.6295s-.65801-2.75487-1.69573-3.62951C6.91851 3.24518 6.2605 4.54669 6.2605 6.00005s.65801 2.75486 1.69573 3.6295Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.3808 10.1838h-2.2391l-1.679-6.59409c-.0797-.30333-.2489-.57149-.4978-.69787-.6212-.31762-1.3057-.57039-2.0524-.69787V1.9401h3.607c.4978 0 .8712.38135.9334.82425l.8712 4.75651 2.238-5.58076h2.1768l-3.3581 8.2437Zm4.6029 0H28.869l1.7413-8.2437h2.1146l-1.7412 8.2437Zm4.477-5.95993c.0622-.444.4356-.69787.8712-.69787.6845-.06374 1.4301.06374 2.0524.38026l.3734-1.7749c-.6223-.25387-1.3068-.38136-1.928-.38136-2.0524 0-3.5459 1.14187-3.5459 2.72664 0 1.20562 1.0579 1.83865 1.8046 2.22.8079.38026 1.119.63413 1.0568 1.01439 0 .57038-.6223.82426-1.2434.82426-.7468 0-1.4935-.19013-2.1769-.50775l-.3734 1.776c.7468.31656 1.5546.44396 2.3013.44396 2.3014.0627 3.7315-1.07809 3.7315-2.79034 0-2.15626-2.9236-2.28265-2.9236-3.23329Zm10.3242 5.95993-1.6791-8.2437h-1.8035c-.3734 0-.7467.25387-.8712.63412l-3.1092 7.60958h2.1769l.4345-1.20455h2.6747l.2489 1.20455h1.928Zm-3.1712-6.02377.6211 3.1069h-1.7412l1.1201-3.1069Z"/>
      <rect width="11" height="11" x="51.6332" y=".5" rx="2.77235"/>
      <path fill="#fff" d="M54.6228 7.94874V5.58616h.8229v2.36258h-.8229Zm2.1014 0V5.58616h.8181v2.36258h-.8181Zm-3.1283 1.4216v-.8944h7.0746v.8944h-7.0746Zm5.2249-1.4216V5.58616h.8229v2.36258h-.8229Zm-5.2249-2.8945V3.5l3.5373-1.15176L60.6705 3.5v1.55424h-7.0746Z"/>
      <path d="M65.7567 9.75278V2.24719h3.1298c.5619 0 1.0322.07941 1.411.23822.3811.15881.667.38114.8575.667.193.28586.2895.61691.2895.99317 0 .28586-.0598.54117-.1795.76595-.1198.22233-.2847.4068-.4948.55339-.2101.1466-.4532.24921-.7293.30785v.07329c.303.01466.5827.09651.8393.24555.2589.14904.4666.35671.623.62302.1563.26387.2345.5766.2345.9382 0 .40313-.1026.76351-.3078 1.08113-.2052.31517-.5021.56438-.8906.74763-.3884.18079-.86.27119-1.4146.27119h-3.368Zm1.8141-1.46227h1.1215c.3933 0 .6828-.07452.8685-.22355.1881-.14904.2822-.35671.2822-.62303 0-.19301-.0452-.35915-.1356-.49841-.0904-.14171-.2187-.25043-.3848-.32617-.1661-.07819-.3653-.11728-.5974-.11728h-1.1544v1.78844Zm0-2.95752h1.0042c.1979 0 .3738-.03299.5277-.09895.1539-.06597.2737-.16126.3592-.28586.0879-.12461.1319-.27486.1319-.45078 0-.25165-.0892-.44955-.2675-.5937-.1784-.14415-.419-.21623-.722-.21623h-1.0335v1.64552Zm6.6627 4.41979h-1.9497l2.5324-7.50559h2.4151l2.5324 7.50559H77.814l-1.7628-5.61453h-.0586l-1.7591 5.61453Zm-.2602-2.95386h4.0753V8.1769h-4.0753V6.79892Zm12.9863-4.55173v7.50559h-1.5392l-2.9868-4.33184h-.0477v4.33184h-1.8141V2.24719h1.5612l2.9539 4.32451h.0623V2.24719h1.8104Zm1.1407 7.50559V2.24719h1.8141v3.17375h.099l2.4811-3.17375h2.1366l-2.6607 3.34967 2.7083 4.15592h-2.1696l-1.869-2.95019-.7257.91621v2.03398h-1.8141Z"/>
    </svg>);
};
