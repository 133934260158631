import React, { useCallback, useContext, useMemo, useRef, } from 'react';
import { observer } from 'mobx-react-lite';
import { invariant } from '../../utils/invariant';
import { useApplication } from './ApplicationContext';
import { useNFT, useSettings } from './SettingsContext';
import { useMerchant } from './MerchantContext';
import { storage } from '../../Services/storage';
const AnalyticsContext = React.createContext(undefined);
export const AnalyticsContextProvider = observer(({ children }) => {
    const merchant = useMerchant();
    const { analyticsInfo } = useSettings();
    const { orderFacade: { order }, } = useApplication();
    const isNft = useNFT();
    const oldTrackProperties = useRef();
    const defaultProps = useMemo(() => ({
        merchant_name: merchant.title,
        from_currency: order === null || order === void 0 ? void 0 : order.amounts.from.currency.a3,
        from_amount: order === null || order === void 0 ? void 0 : order.amounts.from.amount,
        to_currency: order === null || order === void 0 ? void 0 : order.amounts.to.currency.a3,
        to_amount: order === null || order === void 0 ? void 0 : order.amounts.to.amount,
        exchange_rate: order === null || order === void 0 ? void 0 : order.rate,
        screen_height: analyticsInfo
            ? analyticsInfo.screenHeight
            : window.innerHeight,
        screen_width: analyticsInfo
            ? analyticsInfo.screenWidth
            : window.innerWidth,
        merchant_url: analyticsInfo === null || analyticsInfo === void 0 ? void 0 : analyticsInfo.merchantUrl,
        widget_type: isNft ? 'NFT' : 'STANDARD',
        version: 'v2',
        order_id: storage.ORDER_ID.get(),
        direction: 'from',
    }), [
        analyticsInfo,
        isNft,
        merchant.title,
        order === null || order === void 0 ? void 0 : order.amounts.from.amount,
        order === null || order === void 0 ? void 0 : order.amounts.from.currency.a3,
        order === null || order === void 0 ? void 0 : order.amounts.to.amount,
        order === null || order === void 0 ? void 0 : order.amounts.to.currency.a3,
        order === null || order === void 0 ? void 0 : order.rate,
    ]);
    const identify = useCallback((properties) => {
        if (process.env.REACT_APP_ENV_NAME === 'LOCAL') {
            return;
        }
        const userUid = storage.USER_UID.get();
        if (window.analytics) {
            window.analytics.identify(userUid, properties);
        }
        else {
            // eslint-disable-next-line no-console
            console.error('Analytics is not defined');
        }
    }, []);
    const track = useCallback((event, properties, propertiesWithSave) => {
        // if (process.env.REACT_APP_ENV_NAME === 'LOCAL') {
        //   return;
        // }
        if (window.analytics) {
            window.analytics.track(event, Object.assign(Object.assign(Object.assign(Object.assign({}, defaultProps), oldTrackProperties.current), properties), propertiesWithSave));
            if (propertiesWithSave) {
                oldTrackProperties.current = Object.assign(Object.assign({}, oldTrackProperties.current), propertiesWithSave);
            }
        }
        else {
            // eslint-disable-next-line no-console
            console.error('Analytics is not defined');
        }
    }, [defaultProps]);
    return (<AnalyticsContext.Provider value={{ identify, track }}>
        {children}
      </AnalyticsContext.Provider>);
});
export function useAnalytics() {
    const analytics = useContext(AnalyticsContext);
    invariant(analytics, 'useAnalytics should be inside AnalyticsContextProvider');
    return analytics;
}
