import React, { useCallback } from 'react';
import styles from './Menu.module.css';
import { ArrowIcon } from './Icons';
import { useAnalytics } from '../../Contexts/AnalyticsContext';
import { useApplication } from '../../Contexts/ApplicationContext';
import { useLingui } from '@lingui/react';
export const MenuItem = ({ label, Icon, onClick, href }) => {
    const { _ } = useLingui();
    const { track } = useAnalytics();
    const { stepController: { currentStep }, } = useApplication();
    const translatedLabel = _(label);
    const handleClick = useCallback(() => {
        track('hamburger_menu_clicked', {
            button: translatedLabel,
            step: currentStep.step,
        });
        onClick === null || onClick === void 0 ? void 0 : onClick();
    }, [currentStep.step, translatedLabel, onClick, track]);
    const testId = `item-${translatedLabel.toLowerCase().split(' ').join('-')}`;
    return (<a href={href} target="_blank" rel="noreferrer" className={styles.option} data-test-id={testId} onClick={handleClick}>
      {Icon}
      <span className={styles.option_label}>{translatedLabel}</span>
      <ArrowIcon className={styles.arrow_icon}/>
    </a>);
};
