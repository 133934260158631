const localStorageKey = 'WALLETS';
const saveWallets = (wallets) => {
    localStorage.setItem(localStorageKey, `${JSON.stringify(wallets)}`);
};
const savedWallets = () => {
    const walletString = localStorage.getItem(localStorageKey);
    return walletString ? JSON.parse(walletString) : {};
};
const EVM_BASED_KEY = 'evm_based_currency';
const addressKey = (currency) => { var _a; return ((_a = currency.capabilities) === null || _a === void 0 ? void 0 : _a.evm) ? EVM_BASED_KEY : currency.a3; };
export const savedAddress = (currency) => savedWallets()[addressKey(currency)] || '';
export const saveAddress = (address, currency) => saveWallets(Object.assign(Object.assign({}, savedWallets()), { [addressKey(currency)]: address }));
