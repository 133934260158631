import React, { useContext } from 'react';
import { invariant } from '../../utils/invariant';
const CurrenciesContext = React.createContext(undefined);
export const CurrenciesContextProvider = ({ children, currencies, }) => {
    return (<CurrenciesContext.Provider value={currencies}>
      {children}
    </CurrenciesContext.Provider>);
};
export function useCurrencies() {
    const currencies = useContext(CurrenciesContext);
    invariant(currencies, 'Currencies is not defined');
    return currencies;
}
export function useCurrencyIcon(a3) {
    var _a;
    const currencies = useCurrencies();
    return ((_a = currencies.currenciesMap.get(a3)) === null || _a === void 0 ? void 0 : _a.imageUrl) || '';
}
