import React from 'react';
import { useSettings } from '../../Contexts/SettingsContext';
import styles from './PageLayout.module.css';
import { useApplication } from '../../Contexts/ApplicationContext';
import { Page } from '../../../Services/controllers/StepController';
export const ProgressLine = () => {
    const { colors } = useSettings();
    const isDefaultActionColor = Boolean(!(colors === null || colors === void 0 ? void 0 : colors.main));
    const percent = usePercent();
    if (!percent) {
        return null;
    }
    return (<div className={styles.line}>
      <div style={{ width: `${percent}%` }} className={`${styles.percent_line} ${isDefaultActionColor ? styles.percent_line_default : ''}`}/>
    </div>);
};
const percentMap = new Map(Object.entries({
    [Page.MAIN]: 10,
    [Page.PHONE]: 20,
    [Page.PHONE_OTP]: 30,
    [Page.EMAIL_ADDRESS]: 40,
    [Page.EMAIL_ADDRESS_OTP]: 50,
    [Page.TARGET_ADDRESS]: 60,
    [Page.KYC]: 80,
    [Page.CHECK_ORDER]: 100,
}));
const usePercent = () => {
    const { stepController } = useApplication();
    const step = stepController.currentStep;
    return percentMap.get(step.step);
};
