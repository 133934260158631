import React from 'react';
import { CaptionLogo } from './CaptionLogo';
import { CaptionPaymentMethods } from './CaptionPaymentMethods';
import styles from './Caption.module.css';
import { useSettings } from '../../../Contexts/SettingsContext';
import { FeatureFlag, useFeatureFlag, } from '../../../Contexts/FeatureFlagContext';
import { Trans } from '@lingui/macro';
const LINK_FOR_LOGO = 'https://swipelux.com/?utm_source=swipelux_widget&utm_medium=referral&utm_campaign=Inbound';
export const Caption = () => {
    return (<div className={styles.caption_wrapper}>
      <div className={styles.caption_item}>
        <Trans>Pay with</Trans>
        <CaptionPaymentMethods />
      </div>
      <SignatureLink />
    </div>);
};
function SignatureLink() {
    const { signature } = useSettings();
    const enableWhiteLabel = useFeatureFlag(FeatureFlag.ENABLE_WHITELABEL);
    if (enableWhiteLabel && (!signature || !signature.text)) {
        return null;
    }
    const captionLink = enableWhiteLabel ? signature === null || signature === void 0 ? void 0 : signature.href : LINK_FOR_LOGO;
    const CaptionContent = () => enableWhiteLabel ? (<>
        <span className={styles.signature_text}>{signature === null || signature === void 0 ? void 0 : signature.text}</span>
        {(signature === null || signature === void 0 ? void 0 : signature.logoUrl) && (<img className={styles.signature_logo} src={signature.logoUrl}/>)}
      </>) : (<>
        <span className={styles.signature_text}>
          <Trans>Powered by</Trans>
        </span>
        <CaptionLogo />
      </>);
    return (<a data-test-id="button-swipelux" className={styles.signature_link} href={captionLink} target="_blank" rel="noreferrer">
      <CaptionContent />
    </a>);
}
